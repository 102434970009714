import React, {createContext} from "react";
import axios from 'axios';
import {
    localIP as ipAddress
} from "../utilities/urls";

export const PhaseContext = createContext();


class PhaseContextProvider extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            mainTabValue: 0,
            phaseTabValue: 0,
            tableContentAll: null,
            tableContentUser: null,
            tableContentResponsiblePerson: null,
            tableContent: null,
            infoContent: null,
            propositionForView: null,
        }
    };
    componentDidMount() {
    }


    setMainTabValue = (value) => {
        this.setState({
            mainTabValue: value
        })
    };
    setPhaseTabValue = (value) => {
        this.setState({
            phaseTabValue: value
        })
        this.resetTableContent();
        this.resetPropositionForView();

    };
    resetTableContent = () => {
        this.setState({
            tableContent: null
        })
    };
    resetPropositionForView = () => {
        this.setState({
            propositionForView: null
        })
    };
    getArchivedData = (business_id, written_by_user_id, status_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'get_archived_data.php',
            data:{
                business_id,
                status_id,
                written_by_user_id
            }
        })
            .then(
                res => {
                    this.setState({
                        tableContent: res.data.table_content
                    })
                }
            )
    }
    getTableContentForAll = (business_id, status_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'get_table_content_for_all.php',
            data: {
                business_id,
                status_id
            }
        })
            .then(
                res => {
                    this.setState({
                        tableContent: res.data.table_content
                    })
                }
        )
    }
    getTableContentForUser = (user_id, status_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'get_table_content_for_user.php',
            data: {
                user_id,
                status_id
            }
        })
            .then(
                res => {
                    this.setState({
                        tableContent: res.data.table_content
                    })
                }
            )
    }
    getTableContentForPersonResponsible = (business_id, user_id, role_id, status_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'get_table_content_for_person_responsible.php',
            data: {
                business_id,
                user_id,
                role_id,
                status_id
            }
        })
            .then(
                res => {
                    this.setState({
                        tableContent: res.data.table_content
                    })
                }
            )
    }
    setTableContent = (
        business_id,
        user_id,
        status_id,
        view,
        role_id = "0"
    ) => {
        switch (view) {
            case "all":
                this.getTableContentForAll(business_id, status_id);
                break;
            case "mine":
                this.getTableContentForUser(user_id, status_id);
                break;
            case "my_responsibility":
                this.getTableContentForPersonResponsible(business_id, user_id, role_id, status_id)
                break;
            case "myResponsibility":
                this.getTableContentForPersonResponsible(business_id, user_id, role_id, status_id)
                break;
            default:
                break;
        }
    };
    setPropositionForView = (proposition_id) => {
        axios({
            method: 'post',
            url: ipAddress + "get_proposition_for_view.php",
            data: {
                proposition_id
            }
        })
            .then(
                res => {
                    console.log(res);
                    this.setState({
                        propositionForView: res.data.proposition_for_view
                    })
                }
            )
    };
    acceptResponsibility = (proposition_id, user_id, role_user_id) => {
        axios({
            method: 'post',
            url: ipAddress + "accept_responsibility.php",
            data: {
                proposition_id,
                user_id,
                role_user_id
            }
        })
    };
    declineResponsibility = (proposition_id, user_id, status_id) => {
        axios({
            method: 'post',
            url: ipAddress + "decline_responsibility.php",
            data: {
                proposition_id,
                user_id,
                status_id
            }
        })
    }
    advanceProposition = (proposition_id, old_status_id, extra_info) => {
        axios({
            method: 'post',
            url: ipAddress + 'advance_proposition.php',
            data:{
                proposition_id,
                old_status_id,
                extra_info
            }
        })
    };
    returnProposition = (proposition_id, old_status_id, extra_info) => {
        axios({
            method: 'post',
            url: ipAddress + 'return_proposition.php',
            data:{
                proposition_id,
                old_status_id,
                extra_info
            }
        })
    }
    ideaBackToAuthor = (proposition_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'idea_back_to_author.php',
            data:{
                proposition_id
            }
        })
        .then(
            res => {
                if(res.data.return === "true"){
                    console.log("Returned");
                } else {
                    console.log(res.data.message);
                }
            }
        )
    }
    render() {
        let value = {
            mainTabValue: this.state.mainTabValue,
            phaseTabValue: this.state.phaseTabValue,
            tableContent: this.state.tableContent,
            infoContent: this.state.infoContent,
            propositionForView: this.state.propositionForView,

            setPhaseTabValue: this.setPhaseTabValue,
            setTableContent: this.setTableContent,
            resetTableContent: this.resetTableContent,
            setPropositionForView: this.setPropositionForView,
            resetPropositionForView: this.resetPropositionForView,
            acceptResponsibility: this.acceptResponsibility,
            declineResponsibility: this.declineResponsibility,
            advanceProposition: this.advanceProposition,
            returnProposition: this.returnProposition,
            getArchivedData: this.getArchivedData,
            ideaBackToAuthor: this.ideaBackToAuthor,

        };

        return(
            <PhaseContext.Provider
                value={value}
            >
                {this.props.children}
            </PhaseContext.Provider>
        )
    }
}
export default PhaseContextProvider;