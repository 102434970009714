import React, {createContext} from "react";

export const ShowModalsContext = createContext();

class ShowModalsContextProvider extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            discussionForumOpen: false,
        };
    }

    componentDidMount(){

    }

    // toggle Discussion Forum
    toggleDiscussionForumOpen = () => {
        console.log("-------------------------");
        console.log(this.state.discussionForumOpen);
        this.setState({
            discussionForumOpen: !this.state.discussionForumOpen
        });
    }


    render(){
        const {
            discussionForumOpen
        } = this.state;

        return(
            <ShowModalsContext.Provider
                value={{
                    discussionForumOpen,
                    toggleDiscussionForumOpen: this.toggleDiscussionForumOpen,

                }}
            >
                {this.props.children}
            </ShowModalsContext.Provider>
        )
    }
}

export default ShowModalsContextProvider;