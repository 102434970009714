import React, {Component} from "react";
import AuthContextProvider from "./contexts/AuthContext";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./components/home/Home";
import PrivateRoute, { HomeRoute } from "./components/PrivateRoute";
import PrivateHome from "./components/private_home/PrivateHome";
import RoutingContextProvider from "./contexts/RoutingContext";
import AppContextProvider from "./contexts/AppContext";
import PhaseContextProvider from "./contexts/PhaseContext";
import ShowModalsContextProvider from "./contexts/ShowModalsContext";
import axios from "axios";
import { localIP } from "./utilities/urls";


class MyErrorBoundary extends Component {
  state = {
    errorMessage: ''
  }
  static getDerivedStateFromError(error) {
    return {errorMessage: error.toString()}
  }
  componentDidCatch(error, info) {
    try {
      this.logErrorToServices(error.toString(), info.componentStack)
    } catch (error) {
      console.log(error)
    }
  }
  // logErrorToServices = console.log
  logErrorToServices = (error, info) => {
    axios({
      method: 'POST',
      url: localIP + 'log_error_to_service.php',
      data: {
        error,
        info
      }
    })
  }
  render() {
    if (this.state.errorMessage) {
      return (
        <html>
          <h1>Ein Fehler ist aufgetreten</h1>
          <p>
            Wir bitten um Entschuldigung. <br></br>
            Eine Fehlermeldung wurde abgesetzt und der Entwickler wurde kontaktiert.
          </p>
          <h3>Bitte <em>aktualisieren</em> Sie die Seite und versuchen Sie es erneut.</h3>
          <br></br><hr></hr>
          <h6>
            Falls Sie diesen Fehler zusätzlich selber MElden möchten, hier die Meldung:
          </h6>
          <p>
            {this.state.errorMessage}   
        </p>
        </html>
      )
    }
    return this.props.children
  }
}

function App() {

  return (
    <MyErrorBoundary>
      <AuthContextProvider>
      <RoutingContextProvider>
        <AppContextProvider>
          <PhaseContextProvider>
            <ShowModalsContextProvider>
              <Router>
                <Switch>
                  <HomeRoute exact path="/">
                    <Home />
                  </HomeRoute>
                  <PrivateRoute path="/private-home">
                    <PrivateHome />
                  </PrivateRoute>
                </Switch>
              </Router>
            </ShowModalsContextProvider>
          </PhaseContextProvider>
        </AppContextProvider>
      </RoutingContextProvider>
    </AuthContextProvider>
    </MyErrorBoundary>
  );
}
export default App;
