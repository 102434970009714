import React, {useContext, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {styles} from "../../styles/lswiStyles";
import IconButton from "@material-ui/core/IconButton";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {medalsObject} from "../../utilities/medalsObject";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import {AppContext} from "../../contexts/AppContext";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import StarIcon from '@material-ui/icons/Star';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {indigo} from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";


const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);
const useStyles = makeStyles((theme ) => ({
    cardSubheading: {
        [theme.breakpoints.down('sm')]:{
            display: 'none'
        },
        [theme.breakpoints.up('md')]:{
            display: 'line'
        }
    }
}));

const CustomToolTip = (props) => {
    const {
        medalRank,
        doneCount,
        achievementZone,
    } = props;
    return(
        <LightTooltip
            style={{ tooltip: { backgroundColor: 'black'}}}
            title={
                <React.Fragment>
                    <Typography
                        variant="h6"
                        color="primary"
                    >
                        {medalRank}
                    </Typography>
                    <Typography
                        variant="body1"
                    >
                        Anzahl: {doneCount}
                    </Typography>
                    <Divider />
                    <Typography
                        variant="caption"
                    >
                        Gold: bei {achievementZone.gold}<br/>
                        Silber: bei {achievementZone.silver}<br/>
                        Bronze: bei {achievementZone.bronze}<br/>
                        Papier: bei {achievementZone.paper}
                    </Typography>
                </React.Fragment>
            }
        >
            {props.children}
        </LightTooltip>
    )
}

export default function MedalsInfoCard(props){
    const classes = useStyles();
    const [medalsDialogOpen, setMedalsDialogOpen] = useState(false);
    const {
        medals_object,
        permanent_medals_object,
        medalsArray,
        updateMedals
    } = useContext(AppContext);
    const {userData} = useContext(AuthContext);

    if(medals_object === null || medals_object === undefined || medalsArray === null || medalsArray === undefined){
        updateMedals(userData.user_id)
    }

    const temporaryMedals = () => {
            return(
                <Grid
                    item md={12}
                    container spacing={2}
                >
                    {
                        Object.keys(medalsObject).map(
                            (key, index) => {
                                let tmp_color = "";
                                let is_paper = false;
                                let is_active = false;
                                let tmp_count = 0;
                                let tmp_medal_rank = medalsObject[key].name;
                                let tmp_image = require("../../images/inaktiv/" + medalsObject[key].inactive + ".png");
                                if(medalsArray.includes(key)){
                                    tmp_image = require("../../images/aktiv/" + medalsObject[key].active + ".png")
                                    switch (medals_object[key].medal_rank) {
                                        case 'gold':
                                            tmp_color = "#FFD700";
                                            break;
                                        case 'silver':
                                            tmp_color = "#C0C0C0";
                                            break;
                                        case 'bronze':
                                            tmp_color = "#CD7F32";
                                            break;
                                        case 'paper':
                                            tmp_color = 'white';
                                            is_paper = true;
                                            break;
                                        default: break;
                                    }
                                    is_active = true;
                                    tmp_count = medals_object[key].task_count;
                                    tmp_medal_rank = medals_object[key].medals;
                                }
                                return(
                                    <Grid
                                        item key={'temp'+medalsObject[key].name}
                                    >
                                        <CustomToolTip
                                            medalRank={tmp_medal_rank}
                                            doneCount={tmp_count}
                                            achievementZone={{
                                                gold: medalsObject[key].gold,
                                                silver: medalsObject[key].silver,
                                                bronze: medalsObject[key].bronze,
                                                paper: medalsObject[key].paper
                                            }}
                                        >
                                            <Badge
                                                style={{
                                                    marginRight: 8
                                                }}
                                                badgeContent={
                                                    is_active && !is_paper ?
                                                        <Avatar

                                                            style={{
                                                                width: 18,
                                                                height: 18,
                                                                backgroundColor: 'white',
                                                            }}
                                                        > <StarIcon
                                                            style={{
                                                                color: tmp_color
                                                            }}
                                                        />
                                                        </Avatar> : null


                                                }
                                            >
                                                <Avatar

                                                    src={tmp_image} />
                                            </Badge>
                                        </CustomToolTip>

                                    </Grid>
                                )
                            }
                        )
                    }
                </Grid>
            )


    };
    const permanentMedals = () => {
        if(medals_object === null || medals_object === undefined || medalsArray === null || medalsArray === undefined){
            return(
                <div></div>
            )
        } else {
            return(
                <Grid
                    item md={12} container spacing={2}
                >
                    {
                        Object.keys(medalsObject).map(
                            (key, index) => {
                                let tmp_count = permanent_medals_object[key] !== undefined ?
                                    permanent_medals_object[key].task_count !== "0" || permanent_medals_object[key].task_count !== 0 ?
                                        permanent_medals_object[key].task_count :
                                        "0" : "0"
                                ;
                                // let tmp_count = stateValues.permanent_medals_object[key].task_count === undefined || stateValues.permanent_medals_object[key] === undefined ? "0" : stateValues.permanent_medals_object[key].task_count;
                                let tmp_medal_rank = medalsObject[key].name;
                                let tmp_image = require("../../images/inaktiv/" + medalsObject[key].inactive + ".png");


                                if(permanent_medals_object[key] !== undefined && permanent_medals_object[key].got_medal === "1"){
                                    // tmp_count = stateValues.permanent_medals_object[key].task_count;
                                    // TODO: medals === "0"
                                    tmp_count = permanent_medals_object[key].task_count;
                                    tmp_medal_rank = permanent_medals_object[key].medals;
                                    tmp_image = require("../../images/aktiv/" + medalsObject[key].active + ".png")
                                }


                                return(
                                    <Grid
                                        item key={'perm'+medalsObject[key].name}
                                    >
                                        <div>
                                            <LightTooltip
                                                title={
                                                    <React.Fragment>
                                                        <Typography
                                                            variant="h6"
                                                            color="primary"
                                                        >
                                                            {medalsObject[key].name}
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                        >
                                                            {tmp_medal_rank}
                                                        </Typography>
                                                        <Typography
                                                            variant="body1"
                                                        >
                                                            Anzahl: {tmp_count}
                                                        </Typography>
                                                        <Divider />
                                                        <Typography
                                                            variant="caption"
                                                        >
                                                            Auszeichnung bei {medalsObject[key].permanent}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            >
                                                <Avatar
                                                    style={{
                                                        marginRight: 8
                                                    }}
                                                    src={tmp_image} />

                                            </LightTooltip>
                                        </div>
                                    </Grid>
                                )
                            }
                        )
                    }
                </Grid>
            )
        }

    };
    const renderMedalsInfoDialog = () => {

        return(
            <Dialog
                open={medalsDialogOpen}
                onClose={() => setMedalsDialogOpen(false)}
            >
                <DialogTitle
                    style={
                        {backgroundColor: indigo[500],
                            color: 'white' }
                    }
                >
                    Übersicht über die Medaillen und ihre Bedeutung
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            md={12}
                        >
                            <Typography
                                variant="h6"
                                color="primary"
                            >
                                Erklärung:
                            </Typography>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                            >
                                Auszeichnung sind sowohl "temporär" als auch "permanent" zu erreichen.<br/>
                                Die "temporären" Auszeichnungen werden zum ungeraden Monat zurückgesetzt und haben verschiedene Ausprägungen, die passend ihrer Häufigkeit wie folgt sind und durch einen entsprechend farbigen Kreis an der Medaille angezeigt werden: Gold, Silver, Bronze, Papier,<br/>
                                Die "permanenten" Auszeichnungen werden nicht gelöscht und basieren auf einer absoluten Häufigkeit.<br/>
                                Noch nicht errungene Auszeichnungen sind "farblos".<br/>
                                "Farbig" werden Auszeichungen ab der Ausprägung "Papier" (temporär) oder ab erreichen der absoluten Häufigkeit (permanent).
                            </Typography>
                            {activeMedalsOverview()}
                        </Grid>
                        <Grid
                            item
                            md={12}
                        >

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={()=>setMedalsDialogOpen(false)}
                    >
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        )
    };
    const activeMedalsOverview = () => {

        return(
            <div>
                <List>
                    {
                        Object.keys(medalsObject).map(
                            (key, index) => {
                                let tmp_img = require("../../images/aktiv/" + medalsObject[key].active + ".png")
                                return(
                                    <ListItem
                                        key={key}
                                    >
                                        <ListItemAvatar>
                                            <Avatar src={tmp_img}>

                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            disableTypography
                                            primary={medalsObject[key].name}
                                            secondary={
                                                <Typography>
                                                    {medalsObject[key].description}<br/>
                                                    Papier: [{medalsObject[key].paper}]<br/>
                                                    Bronze: [{medalsObject[key].bronze}]<br/>
                                                    Silber: [{medalsObject[key].silver}]<br/>
                                                    Gold: [{medalsObject[key].gold}] - Absolut: [{medalsObject[key].permanent}]
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                )
                            }
                        )
                    }

                </List>
            </div>
        )
    };

    const inactiveMedals = () => {
        return(
            <Grid
                item md={12}
                container spacing={2}
            >
                {
                    Object.keys(medalsObject).map(
                        (key, index) => {
                            let tmp_image = require("../../images/inaktiv/" + medalsObject[key].inactive + ".png");
                            return(
                                <Grid
                                    item md={1} key={'inactive_key'+medalsObject[key].name}
                                >
                                    <LightTooltip
                                        style={{
                                            tooltip: {
                                                backgroundColor: 'black'
                                            }
                                        }}
                                        title={
                                            <React.Fragment>
                                                <Typography
                                                    variant="h6"
                                                    color="primary"
                                                >
                                                    {medalsObject[key].name}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                >
                                                    Anzahl: 0
                                                </Typography>
                                                <Divider />
                                                <Typography
                                                    variant="caption"
                                                >
                                                    Gold: bei {medalsObject[key].gold}<br/>
                                                    Silber: bei {medalsObject[key].silver}<br/>
                                                    Bronze: bei {medalsObject[key].bronze}<br/>
                                                    Papier: bei {medalsObject[key].paper}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    >
                                            <Avatar src={tmp_image} />
                                    </LightTooltip>
                                </Grid>
                            )
                        }
                    )
                }
            </Grid>
        )
    }

    return(
        <Card>
            <CardHeader
                style={styles.cardHeader}
                title="Medailleninfo"
                subheader={<span className={classes.cardSubheading}>Hier sehen Sie Ihre Medaillen.</span>}
                action={
                    <IconButton
                        size="small"
                        onClick={
                            () => setMedalsDialogOpen(true)
                        }
                    >
                        Legende: Medaillen
                    </IconButton>
                }
            />
            <CardContent>
                <Grid
                    container spacing={2}
                >
                    <Grid
                        container spacing={2}
                        item md={12}
                    >
                        <Typography
                            variant="h6"
                            color="primary"
                        >Deine temporären Erfolge</Typography>
                        {medalsArray !== null ? temporaryMedals(): inactiveMedals()}


                    </Grid>

                    <Divider variant="fullWidth" />
                    <Grid
                        item md={12}
                    >
                        <Typography
                            variant="h6"
                            color="primary"
                        >Deine dauerhaften Erfolge</Typography>
                        {medalsArray !== null ? permanentMedals(): inactiveMedals()}
                    </Grid>
                </Grid>
            </CardContent>
            {renderMedalsInfoDialog()}
        </Card>
    )
}