import React, {useContext} from "react";
import Grid from "@material-ui/core/Grid";
import GamerInfoCard from "./GamerInfoCard";
// import {styles} from '../../styles/lswiStyles';
import MedalsInfoCard from "./MedalsInfoCard";
import {AppContext} from "../../contexts/AppContext";
import {AuthContext} from "../../contexts/AuthContext";
import DataInfoCard from "./DataInfoCard";
import RankingInfoCard from "./RankingInfoCard";
import { Container } from "@material-ui/core";


function GameTab(props){
    const{
        updateMedals,
        pointsAdded,
        resetPointsAdded,
    } = useContext(AppContext);
    const {
        userData,
        updateUserData
    } = useContext(AuthContext);

    if(pointsAdded){
        resetPointsAdded();
        setTimeout(
            () => {
                updateMedals(userData.user_id);
                updateUserData(userData.user_id);
            },
            333
        )
    }

    return(
        <Container
            style={{
                marginTop: 10
            }}
            maxWidth="md"
        >
            <Grid
                container
                spacing={3}
            >
                <Grid container
                      spacing={2}
                      item md={12} sm={12} xs={12}
                >
                    <Grid
                        item md={12} sm={12} xs={12}
                    >
                        <GamerInfoCard />
                    </Grid>
                    <Grid
                        item md={12} sm={12} xs={12}
                    >
                        <MedalsInfoCard />
                    </Grid>
                    <Grid
                        item md={12} sm={12} xs={12}
                    >
                        <RankingInfoCard  />
                    </Grid>
                    <Grid
                        item md={12} sm={12} xs={12}
                    >
                        <DataInfoCard />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
export default GameTab;