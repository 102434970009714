/**
 * File-name: DiscussionTopic.js
 * Editor: Karsten Tauchert
 * Created on: 31.05.2019
 */


import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {indigo, purple} from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';


function DiscussionTopic(props){
    const {
        topicPoster,
        topicPosterInitials,
        topicTitle,
        topicText,
        createdAt,
        isReply,
    } = props;

    return(
        <div style={!isReply ? styles.cardContainerTopic : styles.cardContainerReply}  >
            <Paper
                style={{backgroundColor: indigo}}
            >
                <List>
                    <ListItem
                        alignItems="flex-start"
                        key={topicTitle}
                    >
                        <ListItemAvatar>
                            <Avatar
                                style={{
                                    backgroundColor: isReply ? purple[400] : indigo[500]
                                }}
                            >{topicPosterInitials}</Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            disableTypography
                            primary={
                                <Typography
                                    variant="body1"
                                    color="primary"
                                >
                                    {topicTitle}
                                </Typography>
                            }
                            secondary={
                                <React.Fragment>

                                <Typography style={{
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    backgroundColor: '#f4f4f4',
                                    borderStyle: 'solid',
                                    borderColor: '#e6e6e6',
                                    borderWidth: '0.5px'
                                }}
                                    variant="body1"
                                    color="textPrimary"
                                >
                                {topicText}
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex'
                                    }}
                                >
                                    <Typography
                                        style={{flex: 1}}
                                    > </Typography>
                                    <Typography
                                        align="right"
                                        component="span"
                                        variant="subtitle1"
                                        color="textSecondary"
                                    >
                                        {"Erstellt am: " + createdAt + " von: " + topicPoster}
                                    </Typography>
                                </div>

                                </React.Fragment>
                            }
                        />

                    </ListItem>
                </List>


            </Paper>
        </div>
    )

}

const styles =
    {
        cardContainerTopic: {
            paddingLeft: 10,
            paddingRight: 25,
            paddingBottom: 5,
        },
        cardContainerReply: {
            paddingLeft: 35,
            paddingRight: 25,
            paddingBottom: 5
        }
    };


export default DiscussionTopic;