import React from 'react';
import {Typography, Grid, List, ListItem, Link, ListItemAvatar, ListItemText} from "@material-ui/core";
import {Card, CardHeader, CardContent} from '@material-ui/core';
import {styles} from '../../styles/lswiStyles';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import pdfBook from '../../utilities/asikhandbuch.pdf'
import MenuBookIcon from '@material-ui/icons/MenuBook';

export default function ManualVideos(){
 
    return(
        <div>
            <Card
                raised={true}
            >
                <CardHeader
                    style={styles.cardHeader}
                    title="Tutorial und Anleitung"
                />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography
                                    variant="h6" color="textPrimary"
                                >
                                    Hier finden Sie die Links zu den Tutorial-Videos und Handbuch.
                                </Typography>
                                
                                <List>
                                <ListItem
                                        component={Link}
                                        href="https://mediaup.uni-potsdam.de/Browse/Tag/ASiK"
                                            target="_blank" rel="noopener noreferrer"
                                    >   
                                        <ListItemAvatar>
                                            <OndemandVideoIcon color="primary" />
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary="Videos/ Tutorials"
                                            secondary="Erklärung einiger wichtiger Abläufe in Videoform"
                                            />   
                                    </ListItem>
                                    <ListItem
                                        component={Link}
                                        href={pdfBook}
                                            target="_blank" rel="noopener noreferrer"
                                    >   
                                        <ListItemAvatar>
                                            <MenuBookIcon color="primary" />
                                        </ListItemAvatar>
                                        <ListItemText 
                                            primary="Handbuch"
                                            secondary="der Plattform"
                                            />   
                                    </ListItem>
                                </List>
                            </Grid>
                            {/* <Grid item xs={6}>
                                <Typography
                                    variant="h6" color="textPrimary"
                                >
                                    Hier können Sie das Handbuch ansehe.
                                </Typography>
                                <List>
                                    <ListItem
                                        component={Button}
                                        onClick={() => {
                                            setShowManual(!showManual)
                                        }}
                                    >
                                        <ListItemAvatar>

                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={showManual ? "Schließe Handbuch" : "Handbuch öffnen"}
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid sx={12} sm={12} md={12} direction="column"
  alignItems="center"
  justify="center">
     
                                <Box display={showManual ? 'block' : 'none'}>
                                    <PDFViewer
                                        document={{
                                            url: localIP + 'handbuch.pdf',
                                        }}
                                    />
                                </Box>
                            </Grid> */}
                        </Grid>
                    </CardContent>
                    {/* <Divider /> */}
                    {/* <img width="100%" alt={"participants"} src={require('../../images/participants.png')} /> */}
                
            </Card>
        </div>
    )
}
