import React, {createContext} from "react";
import axios from 'axios';
import {
    localIP as ipAddress,
    // onlineIpSecond as IpAddress
} from '../utilities/urls'
export const AppContext = createContext();


class AppContextProvider extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            addNewIdeaDialogOpen: false,
            menuDrawerOpen: false,
            adminViewOpen: false,
            mainTabValue: 0,
            phaseTabValue: 0,

            areas: null,
            departments: null,
            categories: null,
            users: null,
            snackbarOpen: false,
            standardPoints: "",
            marketPoints: "",
            initPoints: "",
            marketFactor: "",
            messageType: "success",
            pointsAdded: false,

            medals: null,
            medals_object: null,
            permanent_medals: null,
            permanent_medals_object: null,
            medalsArray: null,
            medalsPermanentArray: null,
            propositionTopics: null,
            propositionTopicsSet: null,
            propositionLikesCount: null,
            userLikedProposition: null,
            extraInfoData: null,

            phasesCount: null,
            questions: null,
            surveyDone: false,

            areaTextFieldError: false,
            departmentTextFieldError: false,
            categoryTextFieldError: false,

            businessList: null,
            usersList: null,

            rankingMostLikesTop: null,
            rankingMostLikes: null,
            ownRankingMostLikes: null,
            rankingMostPointsTop: null,
            rankingMostPoints: null,
            rankingMostIdeasTop: null,
            rankingMostIdeas: null,
            ownRankingIdeas: null,

            addAdditionalPropositionOpen: false,
            topicsCount: null,

            initSaveIdeaData: null,
            initSaveIdeaDataSet: false,
            alternativeIdeaData: null,
            alternativeIdeaDataSet: false,
            tasksList: null,
        }
    }


    handleOpen = () => {
        this.setState({
            snackbarOpen: true
        });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            snackbarOpen: false
        })
    };
    setInitSaveIdeaData = (initSaveIdeaData) => {
        this.setState({
            initSaveIdeaData,
            initSaveIdeaDataSet: true
        })
    };
    resetInitSaveIdeaData = () => {
        this.setState({
            initSaveIdeaData: null,
            initSaveIdeaDataSet: false
        })
    }
    setAlternativeIdeaData = (alternativeIdeaData) => {
        this.setState({
            alternativeIdeaData,
            alternativeIdeaDataSet: true,
        })
    };
    resetAlternativeIdeaData = () => {
        this.setState({
            alternativeIdeaData: null,
            alternativeIdeaDataSet: false,
        })
    }

    setAreaTextFieldError = (error) => {
        this.setState({
            areaTextFieldError: error
        })
    };
    setDepartmentTextFieldError = (error) => {
        this.setState({
            departmentTextFieldError: error
        })
    };
    setCategoryTextFieldError = (error) => {
        this.setState({
            categoryTextFieldError: error
        })
    }
    setMenuDrawerOpen = (isOpen) => {
        this.setState({
            menuDrawerOpen: isOpen
        })
    };
    setAddNewIdeaDialogOpen = (isOpen) => {
        this.setState({
            addNewIdeaDialogOpen: isOpen
        })
    };
    setAdminViewOpen = (value) => {
        this.setState({
            adminViewOpen: value
        })
    }
    setMainTabValue = (value) => {
        this.setState({
            mainTabValue: value
        })

    };
    setPhaseTabValue = (value) => {
        this.setState({
            phaseTabValue: value
        })
    };
    setAddAdditionalPropositionOpen = (isOpen) => {
        this.setState({
            addAdditionalPropositionOpen: isOpen
        })
    }
    toggle
    resetPointsAdded = () => {
        this.setState({
            pointsAdded: false
        })
    };
    resetExtraInfo = () => {
        this.setState({
            extraInfoData: null
        })
    };
    resetPropositionTopics = () => {
        this.setState({
            propositionTopics: null
        })
    };
    postNewIdea = (was_saved=false, is_additional = false, data, addPointsData) => {

        
        axios({
            method: 'post',
            url: ipAddress + 'post_new_idea.php',
            data: {
                data,
                was_saved,
                is_additional
            }
        })
            .then(
                res => {
                    if(res.data.posted){
                        this.addPoints(addPointsData);
                    }
                }
            )

    };
    saveNewIdea = (idea_data, which_case='init') => {

        axios({
            method: 'post',
            url: ipAddress + 'save_new_idea.php',
            data: {
                idea_data,
                which_case
            }
        })
            .then(
                res => {
                    if(!res.data.success){
                        console.log("Fehler")
                    } else {
                        console.log("Erfolg")
                    }
                }
            )
    }
    getSelectData = (business_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'get_select_data.php',
            data: {
                business_id
            }
        })
            .then(
                res => {
                    const {
                        select_data
                    } = res.data;

                    if(res.data.success){
                        this.setState({
                            areas:  select_data.areas,
                            departments:  select_data.departments,
                            categories:  select_data.categories ,
                            users:  select_data.users
                        })
                    }
                }
            )
    }
    addPoints = (data) => {
        // business_id, user_id, task_id, gamer_type_id

        axios({
            method: 'post',
            url: ipAddress + 'add_points.php',
            data: data
        })
            .then(
                res => {
                    this.setState({
                        snackbarOpen: true,
                        standardPoints: res.data.points.std_points,
                        marketPoints: Math.round(res.data.points.market_points*1000)/1000,
                        initPoints: res.data.points.init_points,
                        marketFactor: Math.round(res.data.points.market_factor*1000)/1000,
                        pointsAdded: true
                    });
                    this.setAddNewIdeaDialogOpen(false)
                }
            )
    };
    addNewUser = (userData) => {
        axios({
            method: 'post',
            url: ipAddress + 'add_user.php',
            data: {
                login_username: userData.login_username,
                login_password: userData.login_password,
                first_name: userData.first_name,
                last_name: userData.last_name,
                business_name: userData.business_name,
                department_name: userData.department_name

            }
        })
            .then(
                res => console.log("added")
            )
    };
    getTasksList = (business_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'get_tasks_list.php',
            data: {
                business_id
            }
        })
            .then(
                res => {
                    this.setState({
                        tasksList: res.data.tasks_list
                    })
                }
            )
    };
    addNewSelectEntry = (value, business_id, entry_type) => {
        axios({
            method: 'post',
            url: ipAddress + 'add_new_select_entry.php',
            data: {
                value,
                business_id,
                entry_type
            }
        })
            .then(
                res => {
                    if(res.data.success){
                        this.getSelectData(business_id)
                    }
                }
            )
    };
    updateMedals = (user_id, business_id = "") => {
        function createMedalsArray(medalObject){
            let medalsArray = [];
            Object.keys(medalObject).map(
                // eslint-disable-next-line array-callback-return
                (key, index) => {
                    medalsArray.push(medalObject[key].medal_id)
                }
            );
            return medalsArray;
        }
        axios({
            method: 'post',
            url: ipAddress + 'update_medals.php',
            data: {
                user_id
            }
        })
            .then(
                res => {
                    this.setState({
                        medals: res.data.medals,
                        medals_object: res.data.medals_object,
                        permanent_medals: res.data.permanent_medals,
                        permanent_medals_object: res.data.permanent_medals_object
                    });
                    return res.data;
                }
            )
            .then(
                medalsData => this.setState({
                    medalsArray: createMedalsArray(medalsData.medals),
                    medalsPermanentArray: createMedalsArray(medalsData.permanent_medals)
                })
            )
            .catch(
                error => console.log(error)
            )
    };
    updateRankingData = (business_id, user_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'update_ranking_top_list.php',
            data: {
                business_id,
                user_id
            }
        })
            .then(
                res => {
                    if(res.data.success){
                        this.setState({
                            rankingMostLikesTop: res.data.data.ranking_most_likes_top,
                            rankingMostLikes: res.data.data.ranking_most_likes,
                            ownRankingMostLikes: res.data.data.own_ranking_most_likes,
                            rankingMostPointsTop: res.data.data.ranking_most_points_top,
                            rankingMostPoints: res.data.data.ranking_most_points,
                            rankingMostIdeasTop: res.data.data.ranking_most_ideas_top,
                            rankingMostIdeas: res.data.data.ranking_most_ideas,
                            ownRankingMostIdeas: res.data.data.own_ranking_most_ideas,
                        })
                    }
                }
            )
    }

    addNewTopic = (
        business_id,
        proposition_id,
        written_by_user_id,
        discussion_topic_title,
        discussion_topic_text
    ) => {
        axios({
            method: 'post',
            url: ipAddress + "add_new_topic.php",
            data: {
                business_id,
                proposition_id,
                written_by_user_id,
                discussion_topic_title,
                discussion_topic_text
            }
        })
            .then(
                res => {
                    this.updatePropositionTopics(proposition_id)
                }
            )
    }
    updatePropositionTopics = (proposition_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'update_discussion_topics.php',
            data: {
                proposition_id
            }
        })
            .then(
                res => {
                    this.setState({
                        propositionTopics: res.data.proposition_topics,
                        propositionTopicsSet: true
                    })
                }
            )
    };
    getExtraInfo = (proposition_id, status_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'get_extra_info.php',
            data: {
                proposition_id,
                status_id
            }
        })
            .then(
                res => {
                    console.log(res);
                    this.setState({
                        extraInfoData: res.data
                    })
                }
            )
    };
    likeProposition = (business_id, user_id, proposition_id, gamer_type_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'like_proposition.php',
            data: {
                business_id,
                user_id,
                proposition_id,

            }
        })
            .then(
                res => {
                    if(res.data.success){
                        this.getLikes(
                            user_id,
                            proposition_id
                        );
                        this.addPoints({
                            business_id,
                            user_id,
                            task_id:'like_proposition',
                            gamer_type_id
                        });
                    }
                }
            )
    };
    getLikes = (user_id, proposition_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'get_likes.php',
            data:{
                user_id,
                proposition_id
            }
        })
            .then(
                res => {
                    this.setState({
                        propositionLikesCount: res.data.proposition_likes,
                        userLikedProposition: res.data.user_liked,
                        userLikesCount: res.data.user_likes_count
                    });
                    // return([res.data.user_liked, res.data.user_likes_count])
                }
            )
    };
    getPhasesCount = (business_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'get_phases_count.php',
            data:{
                business_id
            }
        })
            .then(
                res => {
                    this.setState({
                        phasesCount: res.data
                    })
                }
            )
    };
    getSurveyQuestions = () => {
        axios({
            method: 'post',
            url: ipAddress + 'set_up_questionnaire.php',
            data: {

            }
        })
            .then(
                res => {
                    if(res.data.error){
                        alert("Fehler")
                    } else {
                        this.setState({
                            questions: res.data.questions
                        });
                    }
                }
            )
            .catch(
                error => alert(JSON.stringify(error))
            )
    };
    saveSurveyAnswers = (user_id, answerArray2D) => {

        let tmp_answers = {};
        let i = 1;
        answerArray2D.map(
            // eslint-disable-next-line array-callback-return
            value => {
                let j = 1;
                value.map(
                    // eslint-disable-next-line array-callback-return
                    entry => {
                        if(entry){
                            tmp_answers[i] = j;
                        }
                        j += 1;
                    }
                );
                i++;
            }
        );

        axios({
            method: 'post',
            url: ipAddress + 'save_survey_answers.php',
            data: {
                user_id,
                answers: tmp_answers
            }
        })
            .then(
                res => {
                    if(res.data.error){
                        alert("Entschuldigung, ein Fehler ist aufgetaucht!")
                    } else {
                        this.setState({
                            surveyDone: true
                        });
                        this.getSurveyData(user_id);
                        alert("Vielen Dank für das Ausfüllen der Umfrage! Das Ergebnis können Sie auf der Game-Seite sehen.")
                    }
                }
            )
    };

    getSurveyData = (user_id) => {

        axios({
            method: 'post',
            url: ipAddress + 'get_survey_data.php',
            data: {
                user_id
            }
        })
            .then(
                res => {
                    if(res.data.error){

                        alert("Fehler");
                    } else {
                        this.setState({
                            type_short: res.data.gamer_type.type_short,
                            gamer_type_long: res.data.gamer_type.type_long,
                            gamer_type_id: res.data.gamer_type.gamer_type_id
                        })
                    }
                }
            )
    };
    updateImplementationPercentage = (proposition_id, written_by_user_id, implementation_percentage) => {
        axios({
            method: 'post',
            url: ipAddress + 'update_implementation_percentage.php',
            data:{
                proposition_id,
                written_by_user_id,
                implementation_percentage
            }
        })
            .then(res => console.log("updated"))
    };
    getBusinessList = (userDepartment, userBusiness) => {
        if(userDepartment === "Admin" && userBusiness === "LSWI"){
            axios({
                method: 'post',
                url: ipAddress + 'get_businesses.php',
                data: {

                }
            })
                .then(
                    res => {
                        if(res.data.success){
                            this.setState({
                                businessList: res.data.business_list
                            })
                        }
                    }
                )
                .catch(
                    error => console.log(error)
                )
        }
    };
    getUsersForBusiness = (business_id) => {
        axios({
            method: 'post',
            url: ipAddress + "get_users_for_business.php",
            data: {
                business_id
            }
        })
            .then(
                res => {
                    if(res.data.success){
                        this.setState({
                            usersList: res.data.users_list
                        })
                    }
                }
            )
    };
    getTopicsCount = (proposition_id) => {
        axios({
            method: 'post',
            url: ipAddress + 'get_topics_count.php',
            data: {
                proposition_id
            }
        })
            .then(
                res => {
                    this.setState({
                        topicsCount: res.data.topics_count
                    })
                }
            )
    };



    render() {
        const {
            addNewIdeaDialogOpen,
            menuDrawerOpen,
            mainTabValue,
            phaseTabValue,
            areas, departments, categories, users,
            messageType,
            snackbarOpen,
            standardPoints,
            marketPoints,
            marketFactor,
            initPoints,
            medals,
            medals_object,
            permanent_medals,
            permanent_medals_object,
            medalsArray,
            medalsPermanentArray,
            pointsAdded,
            propositionTopics,
            propositionTopicsSet,
            propositionLikesCount,
            userLikedProposition,
            adminViewOpen,
            extraInfoData,
            phasesCount,
            questions,
            surveyDone,
            areaTextFieldError,
            departmentTextFieldError,
            categoryTextFieldError,
            businessList,
            usersList,
            rankingMostLikesTop,
            rankingMostLikes,
            ownRankingMostLikes,
            rankingMostPointsTop,
            rankingMostPoints,
            rankingMostIdeasTop,
            rankingMostIdeas,
            ownRankingMostIdeas,
            addAdditionalPropositionOpen,
            topicsCount,
            initSaveIdeaData,
            alternativeIdeaData,
            initSaveIdeaDataSet,
            alternativeIdeaDataSet,
            tasksList
        } = this.state;


        return(
            <AppContext.Provider
                value={{
                    addNewIdeaDialogOpen,
                    menuDrawerOpen,
                    mainTabValue,
                    phaseTabValue,
                    areas,
                    departments,
                    categories,
                    users,
                    messageType,
                    snackbarOpen,
                    standardPoints,
                    marketPoints,
                    marketFactor,
                    initPoints,
                    medals,
                    medals_object,
                    permanent_medals,
                    permanent_medals_object,
                    medalsArray,
                    medalsPermanentArray,
                    pointsAdded,
                    propositionTopics,
                    propositionTopicsSet,
                    propositionLikesCount,
                    userLikedProposition,
                    adminViewOpen,
                    extraInfoData,
                    phasesCount,
                    questions,
                    surveyDone,
                    areaTextFieldError,
                    departmentTextFieldError,
                    categoryTextFieldError,
                    businessList,
                    usersList,
                    rankingMostLikesTop,
                    rankingMostLikes,
                    ownRankingMostLikes,
                    rankingMostPointsTop,
                    rankingMostPoints,
                    rankingMostIdeasTop,
                    rankingMostIdeas,
                    ownRankingMostIdeas,
                    addAdditionalPropositionOpen,
                    topicsCount,
                    initSaveIdeaData,
                    initSaveIdeaDataSet,
                    alternativeIdeaData,
                    alternativeIdeaDataSet,
                    tasksList,

                    addPoints: this.addPoints,
                    postNewIdea: this.postNewIdea,
                    getSelectData: this.getSelectData,
                    setMenuDrawerOpen: this.setMenuDrawerOpen,
                    setAddNewIdeaDialogOpen: this.setAddNewIdeaDialogOpen,
                    setMainTabValue: this.setMainTabValue,
                    setPhaseTabValue: this.setPhaseTabValue,
                    handleClose: this.handleClose,
                    updateMedals: this.updateMedals,
                    resetPointsAdded: this.resetPointsAdded,
                    updatePropositionTopics: this.updatePropositionTopics,
                    addNewTopic: this.addNewTopic,
                    getLikes: this.getLikes,
                    likeProposition: this.likeProposition,
                    setAdminViewOpen: this.setAdminViewOpen,
                    getExtraInfo: this.getExtraInfo,
                    resetExtraInfo: this.resetExtraInfo,
                    getPhasesCount: this.getPhasesCount,
                    getSurveyQuestions: this.getSurveyQuestions,
                    saveSurveyAnswers: this.saveSurveyAnswers,
                    getSurveyData: this.getSurveyData,
                    updateImplementationPercentage: this.updateImplementationPercentage,
                    addNewUser: this.addNewUser,
                    addNewSelectEntry: this.addNewSelectEntry,
                    setAreaTextFieldError: this.setAreaTextFieldError,
                    setDepartmentTextFieldError: this.setDepartmentTextFieldError,
                    setCategoryTextFieldError: this.setCategoryTextFieldError,
                    getBusinessList: this.getBusinessList,
                    getUsersForBusiness: this.getUsersForBusiness,
                    updateRankingData: this.updateRankingData,
                    resetPropositionTopics: this.resetPropositionTopics,
                    setAddAdditionalPropositionOpen: this.setAddAdditionalPropositionOpen,
                    getTopicsCount: this.getTopicsCount,
                    saveNewIdea: this.saveNewIdea,
                    setInitSaveIdeaData: this.setInitSaveIdeaData,
                    setAlternativeIdeaData: this.setAlternativeIdeaData,
                    resetAlternativeIdeaData: this.resetAlternativeIdeaData,
                    resetInitSaveIdeaData: this.resetInitSaveIdeaData,
                    getTasksList: this.getTasksList,
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        )
    }
}
export default AppContextProvider;