import React, {useContext, useState} from 'react';
import {Typography, TextField, Card, CardHeader, CardContent, CardActions, Dialog, DialogTitle, DialogContent} from '@material-ui/core';
import {
    Link, withRouter
} from 'react-router-dom';
import Button from "@material-ui/core/Button";
import {AuthContext} from "../../contexts/AuthContext";
import {styles} from '../../styles/lswiStyles'
import {RoutingContext} from "../../contexts/RoutingContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import {AppContext} from "../../contexts/AppContext";

function Login(props){
    const {
        userAuthorized,
        authenticationError,
        loginUser,
        logoutUser,
        getUserAuthorized,
        getUserData,
    } = useContext(AuthContext);
    const {
        setCurrentPage
    } = useContext(RoutingContext);
    const {
        addPoints
    } = useContext(AppContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loggingInModalOpen, setLoggingInModalOpen] = useState(false);

    const checkIfForwardToPrivate = () => {

        setLoggingInModalOpen(true);
        setTimeout(
            () => {
                if(userAuthorized || getUserAuthorized()){
                    setLoggingInModalOpen(false);
                    props.history.push('private-home');
                    setCurrentPage('private-home');
                    setTimeout(
                        () => {
                            addPoints({
                                business_id: getUserData()["business_id"],
                                user_id: getUserData()["user_id"],
                                task_id: 'login',
                                gamer_type_id: getUserData()["gamer_type_id"]
                            })
                        },
                        1000
                    )

                } else {
                    setLoggingInModalOpen(false);
                }
            },
            1200
        )
    };

    // checkConnection();
    return(
        <div>
            <Card
                raised={true}
            >
                <CardHeader
                    style={styles.cardHeader}
                    title={
                        !userAuthorized ?
                            "Login" :
                            "Angemeldet"
                    }
                />
                <CardContent>
                    {
                        !userAuthorized ?
                        <form>
                            <TextField
                                required
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                                type="text"
                                id="username"
                                label="Benutzername"
                                autoFocus
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                required
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                type="password"
                                id="username"
                                label="Passwort"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <Typography>
                                {
                                    authenticationError ? "Benutzername und/oder Paswort ist falsch." : userAuthorized ? "Autorisiert" : "Keine Authorisierung"
                                }
                            </Typography>
                        </form> :
                            <Typography>
                                Sie sind angemeldet! Wollen Sie sich <b>abmelden</b>, oder zum <b>Spiel wechseln</b>?
                            </Typography>
                    }
                </CardContent>
                <CardActions>
                    {
                        !userAuthorized ?
                            <div>
                                <a
                                    // style={{textDecoration: 'none', color: 'black'}}
                                    style={{paddingRight: '20px'}}
                                    target={"_blank"}
                                    rel="noopener noreferrer"
                                    href={"mailto:karsten.tauchert@wi.uni-potsdam.de?cc=marcus.grum@wi.uni-potsdam.de;benedict.bender@wi.uni-potsdam.de;johanna.thieme@wi.uni-potsdam.de&subject=ASiK-Plattfom%20Registrierung&body=Guten%20Tag%20Herr%20Tauchert%2C%0D%0A%0D%0Aich%20interessiere%20mich%20f%C3%BCr%20eine%20Registrierung%20zur%20Nutzung%20der%20ASiK-Plattform.%0D%0ABitte%20registrieren%20Sie%20mich%20unter%20folgenden%20Angaben%3A%0D%0A*Nachname%3A%0D%0A*Vorname%3A%0D%0A*Benutzername%3A%0D%0A*Unternehmensname%3A%0D%0A%0D%0AIch%20danke%20Ihnen%20herzlich%20und%20freue%20mich%20auf%20die%20Registrierung.%0D%0A%0D%0A"}
                                >Registrieren?</a>
                                <Button
                                    variant="outlined"
                                    color="default"
                                    //component={Link}
                                    //to="/private-home"
                                    onClick={
                                        () => {
                                            setPassword("");
                                            loginUser(username, password);
                                            checkIfForwardToPrivate();

                                        }

                                    }
                                >Anmelden</Button>
                            </div>
                             :
                            <div>
                                <Button
                                    onClick={
                                        () => logoutUser()
                                    }
                                >Abmelden</Button>
                                <Button
                                    component={Link}
                                    to="/private-home"
                                    onClick={
                                        () => setCurrentPage('private-home')
                                    }
                                >Zum Spiel</Button>
                            </div>
                    }
                </CardActions>
            </Card>
            <Dialog
                open={loggingInModalOpen}
                fullWidth
                onBackdropClick={() => setLoggingInModalOpen(false)}
            >
                <DialogTitle>
                    Melde an...
                </DialogTitle>
                <DialogContent

                >
                    <LinearProgress />
                </DialogContent>
            </Dialog>
        </div>
    )
}
export default withRouter(Login);