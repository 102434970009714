import React, {useContext, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import axios from 'axios';
import Drawer from "@material-ui/core/Drawer";
import {version} from '../../utilities/version';
import FeedbackIcon from '@material-ui/icons/Feedback';
import PersonIcon from '@material-ui/icons/Person';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import MessageIcon from '@material-ui/icons/Message';
import InfoIcon from '@material-ui/icons/Info';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import {
    localIP as ipAddress,
} from '../../utilities/urls'

// import Typography from "@material-ui/core/Typography";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import {AuthContext} from "../../contexts/AuthContext";

import { styles } from "../../styles/lswiStyles";

import MenuIcon from '@material-ui/icons/Menu';

// import Fingerprint from '@material-ui/icons/Fingerprint';
import Laptop from '@material-ui/icons/Laptop';
import AdminView from "./AdminView";
import {RoutingContext} from "../../contexts/RoutingContext";
import Typography from "@material-ui/core/Typography";
import { Dialog, DialogTitle, DialogContent, MenuItem, Select, DialogActions, Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import {TextField} from "@material-ui/core";


function MenuDrawer(props){
    const {
        menuDrawerOpen,
        setMenuDrawerOpen,
        setAdminViewOpen,
    } = useContext(AppContext);
    const {
        userAuthorized,
        userData,
        logoutUser
    } = useContext(AuthContext);
    const {setCurrentPage} = useContext(RoutingContext);

    const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

    const FeedBackDialog = (props) => {
        const {
            userData
        } = props;
        const [username, setUsername] = useState(userData.user_full);
        const [usability, setUsability] = useState(1);
        const [design, setDesign] = useState(1);
        const [functionality, setFunctionality] = useState(1);
        const [description, setDescription] = useState("");

        function sendMail(){
            axios({
                method: 'post',
                url: ipAddress + 'send_mail.php',
                data:{
                    username,
                    usabilityRating: usability,
                    functionalityRating: functionality,
                    designRating: design,
                    description
                }
            })
            .then(
                res => {
                    if(res.data.sent === "true"){
                        alert("Feedback gesendet");
                        setTimeout(() => {
                            setFeedbackDialogOpen(false);
                        }, 3000);
                    } else {
                        alert(res.data.message);
                        setTimeout(() => {
                            setFeedbackDialogOpen(false);
                        }, 5000);
                    }
                }
            )
        }

        return(
            <Dialog
                fullWidth
                maxWidth={"md"}
                open={feedbackDialogOpen}
                onClose={() => setFeedbackDialogOpen(false)}
            >
                <DialogTitle
                    style={styles.cardHeader}
                >
                    Feedback
                </DialogTitle>
                <DialogContent>
                <TextField
                                disabled
                                value={`${userData.user_full}`}
                                onChange={(event, value) => setUsername(event.target.value)}
                                type="text"
                                id="username"
                                label="Benutzername"
                                autoFocus
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                        <FormGroup>
                            <FormControl
                                fullWidth
                                style={{padding: 10}}
                            >
                                <InputLabel id="usability">Benutzerfreundlichkeit</InputLabel>
                                <Select
                                    labelId="usability"
                                    id="usability"
                                    value={usability}
                                    onChange={event => setUsability(event.target.value)}
                                >
                                    
                                    <MenuItem value={1}>Sehr Gut</MenuItem>
                                    <MenuItem value={2}>Gut</MenuItem>
                                    <MenuItem value={3}>Befriedigend</MenuItem>
                                    <MenuItem value={4}>Ausreichend</MenuItem>
                                    <MenuItem value={5}>Mangelhaft</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl
                                style={{padding: 10}}
                            >
                                <InputLabel id="functionality">Funktionalität</InputLabel>
                                <Select
                                    labelId="functionality"
                                    id="functionality"
                                    value={functionality}
                                    onChange={event => setFunctionality(event.target.value)}
                                >
                                    <MenuItem value={1}>Sehr Gut</MenuItem>
                                    <MenuItem value={2}>Gut</MenuItem>
                                    <MenuItem value={3}>Befriedigend</MenuItem>
                                    <MenuItem value={4}>Ausreichend</MenuItem>
                                    <MenuItem value={5}>Mangelhaft</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl
                                style={{padding: 10}}
                            >
                                <InputLabel id="design">Design der App</InputLabel>
                                <Select
                                    labelId="design"
                                    id="design"
                                    value={design}
                                    onChange={event => setDesign(event.target.value)}
                                >
                                    <MenuItem value={1}>Sehr Gut</MenuItem>
                                    <MenuItem value={2}>Gut</MenuItem>
                                    <MenuItem value={3}>Befriedigend</MenuItem>
                                    <MenuItem value={4}>Ausreichend</MenuItem>
                                    <MenuItem value={5}>Mangelhaft</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                            <TextField
                                required
                                value={description}
                                onChange={event => setDescription(event.target.value)}
                                type="text"
                                id="description"
                                label="Bemerkung"
                                fullWidth
                                multiline
                                rows={3}
                                rowsMax={5}
                                margin="normal"
                                variant="outlined"
                            />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={
                            () => {
                                sendMail()
                            }
                        }
                    >Abgeben</Button>
                </DialogActions>
            </Dialog>
        )
    }
    return(
        <Drawer

            open={menuDrawerOpen}
            onClose={
                () => setMenuDrawerOpen(false)
            }
            anchor="left"
        >
            <div>
                <FeedBackDialog userData={userData} />
                <List
                    //style={styles.drawer}
                >
                    <ListItem
                        button
                        onClick={
                            () => setMenuDrawerOpen(false)
                        }
                    >
                        <ListItemIcon>
                            <MenuIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Menü schließen"
                        />
                    </ListItem>
                    <ListItem
                        button
                        onClick={
                            () => {
                                setMenuDrawerOpen(false);
                                setCurrentPage('home');
                                logoutUser()
                            }
                        }
                    >
                        <ListItemIcon>
                            <PowerSettingsNewIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Abmelden"
                        />
                    </ListItem>
                    {
                        userAuthorized && userData.department_name === "Admin" ?
                            <ListItem
                                button
                                onClick={() => {
                                    setMenuDrawerOpen(false)
                                    setAdminViewOpen(true)
                                }
                                }
                            >
                                <ListItemIcon>
                                    <Laptop />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Admin-Ansicht"
                                />
                            </ListItem> :
                            null
                    }
                    <Divider />
                    <ListItem
                        button
                    >
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={userData.user_full}
                            secondary={userData.department_name}
                        />
                    </ListItem>
                    <ListItem
                        button
                    >
                        <ListItemIcon>
                            <LocalParkingIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={<Typography>
                                Punkte: {
                                parseInt(userData.std_points) + parseInt(userData.init_points)
                            }SP
                            ({parseInt(userData.market_points) + parseInt(userData.init_points)} MP)
                            </Typography>}
                            secondary={
                                "SP: Standardpunkte; MP: Marktpunkte"
                            }
                        />
                    </ListItem>
                    <ListItem
                        button

                    >
                        <ListItemIcon>
                            <MessageIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Nachrichten"
                            secondary={"Momentan: " + (userData.messages_count < 1 || userData.messages_count === undefined ? "Keine Nachrichten" : userData.messages_count + " Nachrichten")}
                        />
                    </ListItem>
                    <Divider />
                    <ListItem
                        //component={Link}
                        button
                        onClick={
                            () => setFeedbackDialogOpen(true)
                        }
                    >
                        <ListItemIcon>
                            <FeedbackIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Feedback"}
                            secondary={""}
                        />
                    </ListItem>

                    <Divider />
                    {/* <ListItem
                        button
                    >
                        <ListItemIcon>
                            <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={"Handbuch"}
                            secondary={"Öffnen zum lesen"}
                        />
                    </ListItem>
                    <ListItem
                        button
                    >
                        <ListItemIcon>
                            <OndemandVideoIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={"VideoTutorial"}
                            secondary={"Anschauen (Link)"}
                        />
                    </ListItem>
                    <Divider /> */}
                    <ListItem
                        button
                    >
                        <ListItemIcon>
                            <InfoIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={"ASiK-Plattform Web-Application"}
                            secondary={"Versionsnummer: " + version}
                        />
                    </ListItem>
                </List>
            </div>
            <AdminView/>
        </Drawer>
    )
}
export default MenuDrawer;