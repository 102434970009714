import React, { useContext, useState } from "react";
import { styles } from "../../styles/lswiStyles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { PhaseContext } from "../../contexts/PhaseContext";
import { AuthContext } from "../../contexts/AuthContext";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import { AppContext } from "../../contexts/AppContext";
import Select from "react-select";
import DiscussionTopic from "../DiscussionTopic";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Badge from "@material-ui/core/Badge";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import LinearProgress from "@material-ui/core/LinearProgress";
import Collapse from "@material-ui/core/Collapse";

import { Paper, MobileStepper, Stepper, Step, StepLabel, Box } from "@material-ui/core";

function InformationFromOtherPhases(props) {
  const {
    phase,
    authorFname,
    authorLname,
    comment,
    likesCount,
    topicsCount,
    personDays,
    duration,
    costs,
    risk,
  } = props;

  
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{phase}</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Autor</TableCell>
                  <TableCell>Kommentar</TableCell>
                  {
                    phase === "Diskussionsphase" ? 
                    <React.Fragment>
                      <TableCell>Anzahl: Likes</TableCell>
                      <TableCell>Anzahl: Topics</TableCell>
                    </React.Fragment> : null
                  }
                  {
                    phase === "Beurteilungsphase" ? 
                    <React.Fragment>
                      <TableCell>Personentage</TableCell>
                      <TableCell>Kosten</TableCell>
                      <TableCell>Dauer</TableCell>
                      <TableCell>Risiko</TableCell>
                    </React.Fragment> : null
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {authorFname === null || authorFname === undefined || authorFname === "" ? 
                      "Kein Autor eines Kommentars" : 
                        authorLname + ", " + authorFname}
                  </TableCell>
                  <TableCell>
                    {comment === null || comment === undefined || comment === "" ? 
                      "Kein Kommentar hinterlassen" : 
                        comment}
                  </TableCell>
                  {
                    phase === "Diskussionsphase" ? 
                    <React.Fragment>
                      <TableCell>{likesCount}</TableCell>
                      <TableCell>{topicsCount}</TableCell>
                    </React.Fragment> : null
                  }
                  {
                    phase === "Beurteilungsphase" ? 
                    <React.Fragment>
                      <TableCell>{personDays}</TableCell>
                      <TableCell>{costs}</TableCell>
                      <TableCell>{duration}</TableCell>
                      <TableCell>{risk}</TableCell>
                    </React.Fragment> : null
                  }
                </TableRow>
              </TableBody>
            </Table>
            </Paper>
            
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

function AdditionalInfoArea(props){
  const{
    areas, departments, categories
  } = props

  return(
    <div>
      <Grid
        container spacing={2}
        style={{
          paddingBottom: 10,
        }}
      >
        <Grid container item spacing={2} xs={12} sm={12} md={12}>
          <Grid item md={12} sm={12}>
            <Typography variant="h6" color="textPrimary" align="left">
              Zugehörigkeiten
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Abteilung</TableCell>
                  <TableCell>Kategorie</TableCell>
                  <TableCell>Bereiche</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{departments === "0" ? "Keine gesetzt" : departments}</TableCell>
                  <TableCell>{categories === "0" ? "Keine gesetzt" : categories}</TableCell>
                  <TableCell>{areas === "0" ? "Keine gesetzt": areas}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
function InformationArea(props) {
  const { is_alternative = false, who, which, title, description } = props;

  return (
    <div>
      <Grid
        container
        spacing={2}
        style={{
          paddingBottom: 10,
        }}
      >
        <Grid container item spacing={2} xs={12} sm={12} md={12}>
          <Grid item md={12} sm={12}>
            <Typography variant="h6" color="textPrimary" align="left">
              {which === "proposition" ? "Vorschlag" : "Situation"}
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            {
              is_alternative ? <Typography color="secondary" variant="body1">Alternativer Vorschlag</Typography> : null
            }
            <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Autor</TableCell>
                  <TableCell>Titel</TableCell>
                  <TableCell>Beschreibung</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{who.user_full}</TableCell>
                  <TableCell>{title}</TableCell>
                  <TableCell>{description}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

function AdditionalPropositionsView(props) {
    const {
        additionalPropositionData
    } = props;
    const [additionalExpanded, setAdditionalExpanded] = useState(false);

    return (
      <div>
        <Typography variant="h5" color="textPrimary">
          Ergänzende Vorschläge vorhanden
          <IconButton
            onClick={() => setAdditionalExpanded(!additionalExpanded)}
          >
            {additionalExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Typography>
        <Collapse in={additionalExpanded} timeout="auto" unmountOnExit>
          {additionalPropositionData["additional_propositions"].map(
            (proposition) => {
              return (
                <div>
                  <InformationArea
                    which={"proposition"}
                    title={proposition.proposition_title}
                    description={proposition.proposition_text}
                  />
                </div>
              );
            }
          )}
        </Collapse>
      </div>
    );
}




function TabPhaseContent(props) {
  const { phaseChosen } = props;
  const [infoEnlarged, setInfoEnlarged] = useState(false);
  const [tableEnlarged, setTableEnlarged] = useState(true);
  const [discussionEnlarged, setDiscussionEnlarged] = useState(false);
  const [view, setView] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [actionTextField, setActionTextField] = useState("");
  const [viewPropositionID, setViewPropositionID] = useState(null);

  const [personDays, setPersonDays] = useState("");
  const [estimatedDuration, setEstimatedDuration] = useState("");
  const [estimatedCost, setEstimatedCost] = useState("");
  const [estimatedRisk, setEstimatedRisk] = useState("");

  const [addNewTopicOpen, setAddNewTopicOpen] = useState(false);
  const [newTopicTitle, setNewTopicTitle] = useState("");
  const [newTopicText, setNewTopicText] = useState("");

  const [unsuccessfulDialogOpen, setUnsuccessfulDialogOpen] = useState(false);

  const [implementationPercentage, setImplementationPercentage] = useState("0");
 
  const [selectDataLoaded, setSelectDataLoaded] = useState(false);
  const [percentageIsError, setPercentageIsError] = useState(false);
  
  const { userData } = useContext(AuthContext);
  const {
    setTableContent,
    setPropositionForView,
    resetPropositionForView,
    resetTableContent,
    propositionForView,
    tableContent,
    acceptResponsibility,
    // declineResponsibility,
    advanceProposition,
    // returnProposition,
    ideaBackToAuthor,
  } = useContext(PhaseContext);
  console.log('propositionForView', propositionForView)
  const {
    addPoints, // function
    users,
    propositionTopics,
    updatePropositionTopics, // function
    resetPropositionTopics,
    addNewTopic, // function
    userLikedProposition,
    propositionLikesCount,
    getLikes, // function
    likeProposition,
    getExtraInfo,
    extraInfoData,
    updateImplementationPercentage,
    setAddNewIdeaDialogOpen, // function
    setInitSaveIdeaData, // function
    setAlternativeIdeaData,
    // alternativeIdeaDataSet,
    // initSaveIdeaDataSet,
    getSelectData // function
  } = useContext(AppContext);

  if(!selectDataLoaded){
    getSelectData(userData.business_id);
    setSelectDataLoaded(true);
  }
  if(view === ""){
    if(+phases[phaseChosen]["status_id"] === 1){
      setView('mine');
      setTableContent(
        userData.business_id,
        userData.user_id,
        phases[phaseChosen]["status_id"],
        "mine",
        phases[phaseChosen]["role_id"]
      );
    } else {
      setView('all');
      setTableContent(
        userData.business_id,
        userData.user_id,
        phases[phaseChosen]["status_id"],
        "all",
        phases[phaseChosen]["role_id"]
      );
    }
  }

  // const { discussionForumOpen, toggleDiscussionForumOpen } = useContext(
  //   ShowModalsContext
  // );

  // const handleSelectUser = (event) => {
  //   setSelectedUser(event.target.value);
  // }
  
  const extraInfoContent = () => {
    
    if (extraInfoData !== null || extraInfoData !== undefined) {
      const {
        additional_propositions_data = null,
        responsibility_data = null,
        discussion_data = null,
        decision_data = null,
        implementation_data = null,
      } = extraInfoData;

      switch (parseInt(propositionForView.status_id)) {
        case 3:
          if(responsibility_data === null || responsibility_data === undefined){
            console.log("waiting status 3");
          } else {
            return (
              <div>
                <Typography variant="h5" color="textPrimary">
                  Information aus anderer Phase
                </Typography>
                <InformationFromOtherPhases
                  phase={"Verantwortungsphase"}
                  comment={responsibility_data.responsibility_comment_text}
                  authorFname={responsibility_data.first_name}
                  authorLname={responsibility_data.last_name}
                />
                {/* Ergänzender Vorschläge auflisten, falls vorhanden */}
                {
                  (additional_propositions_data === undefined || additional_propositions_data === null || additional_propositions_data.length < 1) ? null :
                  (
                    <AdditionalPropositionsView
                      additionalPropositionData={additional_propositions_data}
                    />
                  )
                }
                {/* Einenn alternativen Vorschlag kreieren */}
                <Button
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    width: '100%'
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setAlternativeIdeaData({
                      current_situation_id:
                        propositionForView.current_situation_id,
                      current_situation_title:
                        propositionForView.current_situation_title,
                      current_situation_text:
                        propositionForView.current_situation_text,
                      person_responsible_user_id: propositionForView.role_user_id
                    });
                    setAddNewIdeaDialogOpen(true);
                  }}
                >
                  Einen alternativen Vorschlag erstellen
                </Button>
              </div>
            );
          };break;
        case 4:
          if(
            responsibility_data === null || responsibility_data === undefined ||
            discussion_data === null || discussion_data === undefined
          ) {
            console.log("waiting status 4");
          } else {
            return (
              <div>
                <Typography variant="h5" color="textPrimary">
                  Information aus anderen Phasen
                </Typography>
                <InformationFromOtherPhases
                  phase={"Verantwortungsphase"}
                  comment={responsibility_data.responsibility_comment_text}
                  authorFname={responsibility_data.first_name}
                  authorLname={responsibility_data.last_name}
                />
                <Divider variant="inset" />
                <InformationFromOtherPhases
                  phase={"Diskussionsphase"}
                  comment={discussion_data.discussion_comment_text}
                  authorFname={discussion_data.first_name}
                  authorLname={discussion_data.last_name}
                  likesCountShow={true}
                  likesCount={discussion_data["likes_count"]}
                  topicsCount={discussion_data["topics_count"]}
                />
                
                
              </div>
            );
          };break;   
        case 5:
          if(
            responsibility_data === null || responsibility_data === undefined ||
            discussion_data === null || discussion_data === undefined ||
            extraInfoData.assessment_data === null || extraInfoData.assessment_data === undefined
          ) {
            console.log("waiting status 5")
          } else {
            return (
              <div>
                <Typography variant="h5" color="textPrimary">
                  Information aus anderen Phasen
                </Typography>
                <InformationFromOtherPhases
                  phase={"Verantwortungsphase"}
                  comment={responsibility_data.responsibility_comment_text}
                  authorFname={responsibility_data.first_name}
                  authorLname={responsibility_data.last_name}
                />
                <Divider variant="inset" />
                <InformationFromOtherPhases
                  phase={"Diskussionsphase"}
                  comment={discussion_data.discussion_comment_text}
                  authorFname={discussion_data.first_name}
                  authorLname={discussion_data.last_name}
                  likesCountShow={true}
                  likesCount={discussion_data["likes_count"]}
                  topicsCount={discussion_data["topics_count"]}
                />
                <Divider variant="inset" />
                <InformationFromOtherPhases
                  phase={"Beurteilungsphase"}
                  comment={extraInfoData.assessment_data.assessment_comment_text}
                  authorFname={extraInfoData.assessment_data.first_name}
                  authorLname={extraInfoData.assessment_data.last_name}
                  personDays={extraInfoData.assessment_data.person_days}
                  duration={extraInfoData.assessment_data.estimated_duration}
                  costs={parseInt(extraInfoData.assessment_data.estimated_cost)}
                  risk={extraInfoData.assessment_data.estimated_risk}
                />
                
              </div>
            );
          };break;
          
        case 6:
          if(
            responsibility_data === null || responsibility_data === undefined ||
            discussion_data === null || discussion_data === undefined ||
            extraInfoData.assessment_data === null || extraInfoData.assessment_data === undefined ||
            decision_data === null || decision_data === undefined
          ) {
            console.log("waiting status 5")
          } else {
            return (
              <div>
                <Typography variant="h5" color="textPrimary">
                  Information aus anderen Phasen
                </Typography>
                <InformationFromOtherPhases
                  phase={"Verantwortungsphase"}
                  comment={responsibility_data.responsibility_comment_text}
                  authorFname={responsibility_data.first_name}
                  authorLname={responsibility_data.last_name}
                />
                <Divider variant="inset" />
                <InformationFromOtherPhases
                  phase={"Diskussionsphase"}
                  comment={discussion_data.discussion_comment_text}
                  authorFname={discussion_data.first_name}
                  authorLname={discussion_data.last_name}
                  likesCountShow={true}
                  likesCount={discussion_data["likes_count"]}
                  topicsCount={discussion_data["topics_count"]}
                />
                <Divider variant="inset" />
                <InformationFromOtherPhases
                  phase={"Beurteilungsphase"}
                  comment={extraInfoData.assessment_data.assessment_comment_text}
                  authorFname={extraInfoData.assessment_data.first_name}
                  authorLname={extraInfoData.assessment_data.last_name}
                  personDays={extraInfoData.assessment_data.person_days}
                  duration={extraInfoData.assessment_data.estimated_duration}
                  costs={parseInt(extraInfoData.assessment_data.estimated_cost)}
                  risk={extraInfoData.assessment_data.estimated_risk}
                />
                <Divider variant="inset" />
                <InformationFromOtherPhases
                  phase={"Entscheidungsphase"}
                  comment={decision_data.decision_comment_text}
                  authorFname={decision_data.first_name}
                  authorLname={decision_data.last_name}
                />
                <Typography
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                  variant="h6"
                  color="textPrimary"
                >Fortschritt: <em><strong>{implementationPercentage === "0" ? 
                !implementation_data.implementation_percentage ? 
                  0 : implementation_data.implementation_percentage :
                  implementationPercentage} %</strong></em> </Typography>
                <LinearProgress
                  color={percentageIsError ? "secondary" : "primary"}
                  style={{ height: 50, marginBottom: 15}}
                  variant="determinate"
                  value={
                    implementationPercentage === "0" ? 
                      !implementation_data.implementation_percentage ? 
                        0 : implementation_data.implementation_percentage :
                        implementationPercentage
                  }
                /> 
                
              </div>
            );
          };break;
          
        case 7:
          if(
            responsibility_data === null || responsibility_data === undefined ||
            discussion_data === null || discussion_data === undefined ||
            extraInfoData.assessment_data === null || extraInfoData.assessment_data === undefined ||
            decision_data === null || decision_data === undefined ||
            implementation_data === null || implementation_data === null
          ) {
            console.log("waiting status 7")
          } else {
            return <div>
          <Typography variant="h5" color="textPrimary">
            Information aus anderen Phasen
          </Typography>
          <InformationFromOtherPhases
            phase={"Verantwortungsphase"}
            comment={responsibility_data.responsibility_comment_text}
            authorFname={responsibility_data.first_name}
            authorLname={responsibility_data.last_name}
          />
          <Divider variant="inset" />
          <InformationFromOtherPhases
            phase={"Diskussionsphase"}
            comment={discussion_data.discussion_comment_text}
            authorFname={discussion_data.first_name}
            authorLname={discussion_data.last_name}
            likesCountShow={true}
            likesCount={discussion_data["likes_count"]}
            topicsCount={discussion_data["topics_count"]}
          />
          <Divider variant="inset" />
          <InformationFromOtherPhases
            phase={"Beurteilungsphase"}
            comment={extraInfoData.assessment_data.assessment_comment_text}
            authorFname={extraInfoData.assessment_data.first_name}
            authorLname={extraInfoData.assessment_data.last_name}
            personDays={extraInfoData.assessment_data.person_days}
            duration={extraInfoData.assessment_data.estimated_duration}
            costs={parseInt(extraInfoData.assessment_data.estimated_cost)}
            risk={extraInfoData.assessment_data.estimated_risk}
          />
          <Divider variant="inset" />
          <InformationFromOtherPhases
            phase={"Entscheidungsphase"}
            comment={decision_data.decision_comment_text}
            authorFname={decision_data.first_name}
            authorLname={decision_data.last_name}
          />
          <Divider variant="inset" />
          <InformationFromOtherPhases
            phase={"Implementation"}
            comment={implementation_data.implementation_comment_text}
            authorFname={implementation_data.first_name}
            authorLname={implementation_data.last_name}
          />
          <Typography
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
            variant="h6"
            color="textPrimary"
          >Fortschritt: <em><strong>{implementationPercentage === "0" ? 
          !implementation_data.implementation_percentage ? 
            0 : implementation_data.implementation_percentage :
            implementationPercentage} %</strong></em> </Typography>
          <LinearProgress
            color={percentageIsError ? "secondary" : "primary"}
            style={{ height: 50 }}
            variant="determinate"
            value={
              implementationPercentage === "0" ? 
                !implementation_data.implementation_percentage ? 
                  0 : implementation_data.implementation_percentage :
                  implementationPercentage
            }
          /> 
          
        </div>;
          };break;
          
        default:
          return (
            <div>
              
            </div>
          );
      }
    }
  };
  const infoActionContent = () => {
    if (propositionForView !== null) {
      const {
        role_accepted,
        proposition_id,
        proposition_title,
        proposition_text,
        current_situation_id,
        current_situation_title,
        current_situation_text,
      } = propositionForView;

      if (userData.user_id === propositionForView.role_user_id) {
        switch (propositionForView.status_id) {
          case "2":
            if (role_accepted === "0") {
              return (
                <div>
                  <Typography variant="h6">Aktionen:</Typography>
                  <Divider variant={"inset"} />
                  <Typography>
                    Sie haben die Verantwortung für diesen Vorschlag übertragen
                    bekommen. Wollen Sie diese Verantwortung <em>'Akzeptieren'</em>,
                    oder wollen Sie diese <em>'Ablehnen'</em> (und somit
                    zusätzlich zurück zum Überarbeiten geben)?
                  </Typography>

                  <CardActions>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        acceptResponsibility(
                          propositionForView.proposition_id,
                          userData.user_id,
                          propositionForView.role_user_id
                        );
                        setTimeout(() => {
                          setPropositionForView(viewPropositionID);
                        }, 333);
                        resetPropositionForView();
                        addPoints({
                          business_id: userData.business_id,
                          user_id: userData.user_id,
                          task_id: "responsible_action_1",
                          gamer_type_id: userData.gamer_type_id,
                        });
                      }}
                    >
                      Akzeptieren
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        ideaBackToAuthor(propositionForView.proposition_id)
                        // declineResponsibility(
                        //   propositionForView.proposition_id,
                        //   userData.user_id,
                        //   propositionForView.status_id
                        // );
                        setTimeout(() => {
                          setTableContent(
                            userData.business_id,
                            userData.user_id,
                            phases[phaseChosen]["status_id"],
                            view,
                            "0"
                          );
                        }, 333);
                        resetTableContent();
                        resetPropositionForView();
                        setInfoEnlarged(false);
                        addPoints({
                          business_id: userData.business_id,
                          user_id: userData.user_id,
                          task_id: "responsible_action_1",
                          gamer_type_id: userData.gamer_type_id,
                        });
                      }}
                    >
                      Ablehnen
                    </Button>
                  </CardActions>
                </div>
              );
            } else {
              return (
                <div>
                  <Typography variant="h6">Aktionen:</Typography>
                  <Divider variant={"inset"} />
                  <Typography>
                    Da Sie die Verantwortung für diese Idee angenommen haben,
                    können Sie diese nun zur Diskussion <em>weiterleiten</em>
                    oder doch zum Autor <em>zurückleiten</em>.  
                  </Typography>
                  <TextField
                    required
                    value={actionTextField}
                    onChange={(event) => {
                      setActionTextField(event.target.value);
                    }}
                    type="text"
                    id="description"
                    label="Bemerkung"
                    helperText={"Eine kurze Erklärung für Ihre Entscheidung..."}
                    multiline
                    rowsMax={3}
                    rows={2}
                    autoFocus
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!actionTextField && actionTextField.length > 10}
                    onClick={() => {
                      advanceProposition(
                        propositionForView.proposition_id,
                        propositionForView.status_id,
                        {
                          written_by_user_id: userData.user_id,
                          responsibility_comment_text: actionTextField,
                        }
                      );
                      setActionTextField("");
                      setTimeout(() => {
                        setTableContent(
                          userData.business_id,
                          userData.user_id,
                          phases[phaseChosen]["status_id"],
                          'all',
                          "0"
                        );
                      }, 333);
                      resetTableContent();
                      resetPropositionForView();
                      setInfoEnlarged(false);
                      setTableEnlarged(true);
                      setView('all')
                      addPoints({
                        business_id: userData.business_id,
                        user_id: userData.user_id,
                        task_id: "responsible_action_2",
                        gamer_type_id: userData.gamer_type_id,
                      });
                    }}
                  >
                    Weiterleiten
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!actionTextField && actionTextField.length > 10}
                    onClick={() => {
                      ideaBackToAuthor(propositionForView.proposition_id);
                      // returnProposition(
                      //   propositionForView.proposition_id,
                      //   propositionForView.status_id,
                      //   {
                      //     written_by_user_id: userData.user_id,
                      //     annotation_text: actionTextField,
                      //   }
                      // );
                      setActionTextField("");
                      setTimeout(() => {
                        setTableContent(
                          userData.business_id,
                          userData.user_id,
                          phases[phaseChosen]["status_id"],
                          view,
                          "0"
                        );
                      }, 333);
                      resetTableContent();
                      resetPropositionForView();
                      setInfoEnlarged(false);
                      addPoints({
                        business_id: userData.business_id,
                        user_id: userData.user_id,
                        task_id: "responsible_action_2",
                        gamer_type_id: userData.gamer_type_id,
                      });
                    }}
                  >
                    Zurückleiten
                  </Button>
                </div>
              );
            }
          case "3":
            return (
              <div>
                <Typography variant="h6">Aktionen:</Typography>
                <Divider variant={"inset"} />
                <Typography
                  color="textPrimary"
                  variant="body1"
                >
                  Sie können hier die Idee <em>'weiterleiten'</em>. Wählen Sie jemanden aus, der die Beurteilung übernehmen soll.
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="caption"
                >
                  Bitte geben Sie einen Kommentar ab.
                </Typography>
                {/* <Typography>
                  Je nach Ausgang der Diskussion, können Sie entscheiden, ob Sie
                  die Idee zum Autor zur Überarbeitung <em>zurückleiten</em>,
                  oder zur Bewertung <em>weiterleiten</em> möchten.
                  <br />
                  Wählen Sie zusätzlich jemanden der die Bewertung übernehmen
                  soll.
                </Typography> */}
                <Grid container spacing={2}>
                  <Grid item sm={12} md={7}>
                    <TextField
                      required
                      value={actionTextField}
                      onChange={(event) => {
                        setActionTextField(event.target.value);
                      }}
                      type="text"
                      id="description"
                      label="Bemerkung"
                      helperText={
                        "Eine kurze Erklärung für Ihre Entscheidung..."
                      }
                      multiline
                      rowsMax={3}
                      rows={2}
                      autoFocus
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid style={styles.addNewIdeaTextField} item sm={12} md={5}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >Teilnehmerliste</Typography>
                    <Select
                      placeholder={"Einen Verantwortlichen wählen..."}
                      options={users}
                      value={selectedUser}
                      onChange={selectedUser => setSelectedUser(selectedUser)}
                      isMulti={false}
                    />
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    (!actionTextField || actionTextField.length < 10) || selectedUser === null
                  }
                  onClick={() => {
                    advanceProposition(
                      propositionForView.proposition_id,
                      propositionForView.status_id,
                      {
                        discussion_comment_text: actionTextField,
                        written_by_user_id: userData.user_id,
                        selected_user: selectedUser["value"],
                      }
                    );
                    setActionTextField("");
  
                    setTimeout(() => {
                      setTableContent(
                        userData.business_id,
                        userData.user_id,
                        phases[phaseChosen]["status_id"],
                        'all',
                        "0"
                      );
                    }, 333);
                    resetTableContent();
                    resetPropositionForView();
                    setInfoEnlarged(false);
                    setTableEnlarged(true);
                    setView('all')
                    addPoints({
                      business_id: userData.business_id,
                      user_id: userData.user_id,
                      task_id: "discussion_action",
                      gamer_type_id: userData.gamer_type_id,
                    });
                  }}
                >
                  Weiterleiten
                </Button>
                {/* <Button
                  variant="contained"
                  color="secondary"
                  disabled={
                    !actionTextField &&
                    actionTextField.length > 10 &&
                    selectedUser !== null
                  }
                  onClick={() => {
                    returnProposition(
                      propositionForView.proposition_id,
                      propositionForView.status_id,
                      {
                        written_by_user_id: userData.user_id,
                        annotation_text: actionTextField,
                      }
                    );
                    setActionTextField("");
                    setTimeout(() => {
                      setTableContent(
                        userData.business_id,
                        userData.user_id,
                        phases[phaseChosen]["status_id"],
                        view,
                        "0"
                      );
                    }, 333);
                    resetTableContent();
                    resetPropositionForView();
                    setInfoEnlarged(false);
                    addPoints({
                      business_id: userData.business_id,
                      user_id: userData.user_id,
                      task_id: "discussion_action",
                      gamer_type_id: userData.gamer_type_id,
                    });
                  }}
                >
                  Zurückleiten
                </Button> 
                *** TODO: wieder rein nehmen, wenn ausgereift und OK von oben ***
                */}
              </div>
            );
          case "4":
            return (
              <div>
                <Typography variant="h6">Aktionen:</Typography>
                <Divider variant={"inset"} />
                <Typography>
                  Sie haben die Aufgabe für diese Idee eine Bewertung abzugeben.
                  Wenn Sie sich sicher sind, geben Sie die foglenden Daten ein
                  und suchen sich einen Teilnehmer aus, an den Sie den Auftrag
                  zur Entscheidung <em>'weiterleiten'</em> wollen.
                  {/* <br />
                  Sie können die Idee auch wieder an den Author{" "}
                  <em>zurückleiten</em>. */}
                </Typography>
                <Grid container spacing={2}>
                  <Grid style={{ paddingTop: 5 }} item md={6} sm={12} xs={12}>
                    <TextField
                      value={personDays}
                      onChange={(event) => setPersonDays(event.target.value)}
                      type="text"
                      id="personDays"
                      label="Personentage"
                      helperText=""
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      value={estimatedDuration}
                      onChange={(event) =>
                        setEstimatedDuration(event.target.value)
                      }
                      type="text"
                      id="estimatedDuration"
                      label="Dauer in Wochen"
                      helperText=""
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      value={estimatedCost}
                      onChange={(event) => setEstimatedCost(event.target.value)}
                      type="text"
                      id="estimatedCost"
                      label="Kosten in Euro"
                      helperText=""
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      value={estimatedRisk}
                      onChange={(event) => setEstimatedRisk(event.target.value)}
                      type="text"
                      id="estimatedRisk"
                      label="Risiko in Prozent"
                      helperText=""
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      Teilnehmerliste
                    </Typography>
                    <Select
                      placeholder={"Einen Verantwortlichen wählen..."}
                      options={users}
                      value={selectedUser}
                      onChange={(selectedUser) => setSelectedUser(selectedUser)}
                      isMulti={false}
                    />
                    <TextField
                      required
                      value={actionTextField}
                      onChange={(event) => {
                        setActionTextField(event.target.value);
                      }}
                      type="text"
                      id="description"
                      label="Bemerkung"
                      helperText={
                        "Eine kurze Erklärung für Ihre Entscheidung..."
                      }
                      multiline
                      rowsMax={3}
                      rows={2}
                      autoFocus
                      fullWidth
                      margin="normal"
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        personDays === "" || estimatedRisk === "" || estimatedCost === "" || estimatedDuration === "" ||
                        !actionTextField || actionTextField.length < 10 || selectedUser === null
                      }
                      onClick={() => {
                        advanceProposition(
                          propositionForView.proposition_id,
                          propositionForView.status_id,
                          {
                            written_by_user_id: userData.user_id,
                            assessment_comment_text: actionTextField,
                            estimated_cost: estimatedCost,
                            estimated_duration: estimatedDuration,
                            estimated_risk: estimatedRisk,
                            person_days: personDays,
                            selected_user: selectedUser["value"],
                          }
                        );
                        setActionTextField("");
                        setTimeout(() => {
                          setTableContent(
                            userData.business_id,
                            userData.user_id,
                            phases[phaseChosen]["status_id"],
                            'all',
                            "0"
                          );
                        }, 333);
                        resetTableContent();
                        resetPropositionForView();
                        setInfoEnlarged(false);
                        setTableEnlarged(true);
                        setView('all');
                        addPoints({
                          business_id: userData.business_id,
                          user_id: userData.user_id,
                          task_id: "assessment_action",
                          gamer_type_id: userData.gamer_type_id,
                        });
                      }}
                    >
                      Weiterleiten
                    </Button>
                    {/* <Button
                      onClick={() => {
                        returnProposition(
                          propositionForView.proposition_id,
                          propositionForView.status_id,
                          {
                            written_by_user_id: userData.user_id,
                            annotation_text: actionTextField,
                          }
                        );
                        setActionTextField("");
                        setTimeout(() => {
                          setTableContent(
                            userData.business_id,
                            userData.user_id,
                            phases[phaseChosen]["status_id"],
                            view,
                            "0"
                          );
                        }, 333);
                        resetTableContent();
                        resetPropositionForView();
                        setInfoEnlarged(false);
                        addPoints({
                          business_id: userData.business_id,
                          user_id: userData.user_id,
                          task_id: "assessment_action",
                          gamer_type_id: userData.gamer_type_id,
                        });
                      }}
                    >
                      Zurückleiten
                    </Button> 
                      TODO: Wenn OK, dann wieder einbringen
                    */}
                  </Grid>
                </Grid>
              </div>
            );
          case "5":
            return (
              <div>
                <Typography variant="h6">Aktionen:</Typography>
                <Divider variant={"inset"} />
                <Typography
                  variant="body1"
                  color="textPrimary"
                >
                  Ihnen wurde die Entscheidung übertragen, die Umsetzung für diese Idee einzuleiten.

                  Wenn Sie diese Idee <em>jetzt</em> umsetzen lassen möchten, dann wählen Sie einen Teilnehmer, der für die Umsetzung zuständig sein soll und leiten Sie die Idee an diesen weiter.
                </Typography>
                {/* <Typography>
                  Sie haben jetzt die Daten aus der Beurteilung, nun können Sie
                  über die Idee entscheiden. Wollen Sie diese zur Umsetzung{" "}
                  <em>weiterleiten</em>, oder wollen Sie die Idee an den Author{" "}
                  <em>zurückleiten</em>?
                </Typography> */}
                <Select
                  placeholder={"Einen Verantwortlichen wählen..."}
                  options={users}
                  value={selectedUser}
                  onChange={(selectedUser) => setSelectedUser(selectedUser)}
                  isMulti={false}
                />
                <TextField
                  required
                  value={actionTextField}
                  onChange={(event) => {
                    setActionTextField(event.target.value);
                  }}
                  type="text"
                  id="description"
                  label="Bemerkung"
                  helperText={"Eine kurze Erklärung für Ihre Entscheidung..."}
                  multiline
                  rowsMax={3}
                  rows={2}
                  autoFocus
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <Button
                  disabled={
                    !actionTextField || actionTextField.length < 10 || selectedUser === null
                  }
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    advanceProposition(
                      propositionForView.proposition_id,
                      propositionForView.status_id,
                      {
                        written_by_user_id: userData.user_id,
                        decision_comment_text: actionTextField,
                        selected_user: selectedUser["value"],
                      }
                    );
                    setActionTextField("");
                    setTimeout(() => {
                      setTableContent(
                        userData.business_id,
                        userData.user_id,
                        phases[phaseChosen]["status_id"],
                        'all',
                        "0"
                      );
                    }, 333);
                    resetTableContent();
                    resetPropositionForView();
                    setInfoEnlarged(false);
                    setTableEnlarged(true);
                    setView('all')
                    addPoints({
                      business_id: userData.business_id,
                      user_id: userData.user_id,
                      task_id: "decision_action",
                      gamer_type_id: userData.gamer_type_id,
                    });
                  }}
                >
                  Weiterleiten
                </Button>
                {/* <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    returnProposition(
                      propositionForView.proposition_id,
                      propositionForView.status_id,
                      {
                        written_by_user_id: userData.user_id,
                        annotation_text: actionTextField,
                      }
                    );
                    setActionTextField("");
                    setTimeout(() => {
                      setTableContent(
                        userData.business_id,
                        userData.user_id,
                        phases[phaseChosen]["status_id"],
                        view,
                        "0"
                      );
                    }, 333);
                    resetTableContent();
                    resetPropositionForView();
                    setInfoEnlarged(false);
                    addPoints({
                      business_id: userData.business_id,
                      user_id: userData.user_id,
                      task_id: "decision_action",
                      gamer_type_id: userData.gamer_type_id,
                    });
                  }}
                >
                  Zurückleiten
                </Button> 
                  TODO: Wenn OK, wieder rein!
                */}
              </div>
            );
          case "6":
            return (
              <div>
                <Typography variant="h6">Aktionen:</Typography>
                <Divider variant={"inset"} />
                <Typography>
                  Wie weit ist die Umsetzung? Geben Sie einen Prozentsatz an!
                </Typography>
                <TextField
                  error={percentageIsError}
                  value={implementationPercentage}
                  onChange={(event) => {
                    if(+event.target.value > 100 || +event.target.value < 0){
                      setPercentageIsError(true)
                      setImplementationPercentage(0);
                    } else {
                      setPercentageIsError(false)
                      setImplementationPercentage(event.target.value)
                    }
                    
                  }
                  }
                  type="text"
                  id="implementationPercentage"
                  label="Wieviel ist umgesetzt?"
                  helperText="... in Prozent"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <Button
                  style={{
                    marginBottom: 10
                  }}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    updateImplementationPercentage(
                      propositionForView.proposition_id,
                      userData.user_id,
                      implementationPercentage
                    );
                  }}
                >
                  Update
                </Button>
                <Divider />
                
                <Typography
                  style={{
                    paddingTop: 10
                  }}
                  variant="body1"
                  color="textPrimary"
                >
                  Sie können die Idee zur Prüfung an einen anderen Teilnehmer <em>'weiterleiten'</em>.<br></br> Damit beenden Sie Umsetzung an dem Punkt an dem die Idee zurzeit steht. <br></br>
                  Bevor Sie auf <em>'weiterleiten'</em> klicken, gehen Sie bitte Sicher, dass der Fortschritt richtig gespeichert wird, indem Sie den Fortschritt mit dem korrekten Wert <em>'updaten'</em>.
                </Typography>
                <TextField
                  required
                  value={actionTextField}
                  onChange={(event) => {
                    setActionTextField(event.target.value);
                  }}
                  type="text"
                  id="description"
                  label="Bemerkung"
                  helperText={"Eine kurze Beschreibung"}
                  multiline
                  rowsMax={3}
                  rows={2}
                  autoFocus
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <Select
                style={{
                  paddingTop: 10
                }}
                  placeholder={"Einen Verantwortlichen wählen..."}
                  options={users}
                  value={selectedUser}
                  onChange={(selectedUser) => setSelectedUser(selectedUser)}
                  isMulti={false}
                />
                <Button
                style={{
                  marginTop: 10,
                  marginBottom: 10
                }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    advanceProposition(
                      propositionForView.proposition_id,
                      propositionForView.status_id,
                      {
                        written_by_user_id: userData.user_id,
                        implementation_comment_text: actionTextField,
                        selected_user: selectedUser["value"],
                      }
                    );
                    setActionTextField("");
                    setTimeout(() => {
                      setTableContent(
                        userData.business_id,
                        userData.user_id,
                        phases[phaseChosen]["status_id"],
                        'all',
                        "0"
                      );
                    }, 333);
                    resetTableContent();
                    resetPropositionForView();
                    setInfoEnlarged(false);
                    setTableEnlarged(true);
                    setView('all')
                    addPoints({
                      business_id: userData.business_id,
                      user_id: userData.user_id,
                      task_id: "implementation_action",
                      gamer_type_id: userData.gamer_type_id,
                    });
                  }}
                >
                  Weiterleiten
                </Button>
              </div>
            );
          case "7":
            return (
              <div>
                <Typography variant="h6">Aktionen:</Typography>
                <Divider variant={"inset"} />
                <Typography>
                  Sie haben jetzt die Möglichkeit die Idee als erfolgreich oder
                  nicht erfolgreich zu archivieren.
                  <br />
                  Falls Sie sich für nicht erfolgreich entscheiden, können Sie
                  die Idee zur Überarbeitung zurück zum Verfasser schicken,
                  nachdem Sie diesen Durchlauf der Idee als nicht erfolgreich
                  archivieren
                </Typography>
                <TextField
                  required
                  value={actionTextField}
                  onChange={(event) => {
                    setActionTextField(event.target.value);
                  }}
                  type="text"
                  id="description"
                  label="Bemerkung"
                  helperText={"Eine kurze Beschreibung"}
                  multiline
                  rowsMax={3}
                  rows={2}
                  autoFocus
                  fullWidth
                  margin="normal"
                  variant="outlined"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    advanceProposition(
                      propositionForView.proposition_id,
                      propositionForView.status_id,
                      {
                        written_by_user_id: userData.user_id,
                        implementation_comment_text: actionTextField,
                        successful: true,
                        unsuccessful: false,
                        reinitialize: false,
                      }
                    );
                    setActionTextField("");
                    setTimeout(() => {
                      setTableContent(
                        userData.business_id,
                        userData.user_id,
                        phases[phaseChosen]["status_id"],
                        'all',
                        "0"
                      );
                    }, 333);
                    resetTableContent();
                    resetPropositionForView();
                    setInfoEnlarged(false);
                    setTableEnlarged(true);
                    setView('all');
                    addPoints({
                      business_id: userData.business_id,
                      user_id: userData.user_id,
                      task_id: "review_action",
                      gamer_type_id: userData.gamer_type_id,
                    });
                  }}
                >
                  Erfolgreich
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  // TODO: button einfügen!
                  onClick={() => {
                    setUnsuccessfulDialogOpen(true);
                  }}
                >
                  Nicht erfolgreich
                </Button>
              </div>
            );
          default:
            break;
        }
      } else if (
        propositionForView.written_by_user_id === userData.user_id &&
        propositionForView.status_id === "1"
      ) {
        return (
          <div>
            <Typography>
              Ihre Idee ist noch nicht für alle sichtbar und Sie können sie noch
              bearbeiten.
              <br />
              Bearbeiten Sie Ihre Idee jetzt und geben Sie sie danach ab!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setInitSaveIdeaData({
                  current_situation_id,
                  current_situation_title,
                  current_situation_text,
                  proposition_id,
                  proposition_title,
                  proposition_text,
                });
                setAddNewIdeaDialogOpen(true);
              }}
            >
              Bearbeiten/Abgeben
            </Button>
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  };
  const unsuccessfulDialog = () => {
    return (
      <Dialog
        open={unsuccessfulDialogOpen}
        onClose={() => setUnsuccessfulDialogOpen(false)}
      >
        <DialogTitle>
          Aktionen <b>nicht erfolgreiche</b> Idee
        </DialogTitle>
        <DialogContent>
          <Typography>Sie können nun entscheiden, ob Sie die Idee:</Typography>
          <ul>
            <li>
              nur als <em>nicht erfoglreich</em> ins Archiv ablegen möchten
            </li>
            <li>
              oder zusätzlich noch <em>reinitialisieren</em> wollen, so dass der
              Autor diese bearbeiten kann.
            </li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              advanceProposition(
                propositionForView.proposition_id,
                propositionForView.status_id,
                {
                  successful: false,
                  unsuccessful: false,
                  reinitialize: true,
                  proposition_title: propositionForView.proposition_title,
                  proposition_text: propositionForView.proposition_text,
                  current_situation_title:
                    propositionForView.current_situation_title,
                  current_situation_text:
                    propositionForView.current_situation_text,
                  business_id: userData.business_id,
                  written_by_user_id: propositionForView.written_by_user_id,
                  user_id: userData.user_id,
                  review_comment_text: actionTextField,
                }
              );
              setActionTextField("");

              resetTableContent();
              resetPropositionForView();
              setInfoEnlarged(false);
              addPoints({
                business_id: userData.business_id,
                user_id: userData.user_id,
                task_id: "review_action",
                gamer_type_id: userData.gamer_type_id,
              });
              setUnsuccessfulDialogOpen(false);
            }}
          >
            Reinitialisiert
          </Button>
          <Button
            onClick={() => {
              advanceProposition(
                propositionForView.proposition_id,
                propositionForView.status_id,
                {
                  successful: false,
                  unsuccessful: true,
                  reinitialize: false,
                  review_comment_text: actionTextField,
                  user_id: userData.user_id,
                }
              );
              setActionTextField("");

              resetTableContent();
              resetPropositionForView();
              setInfoEnlarged(false);
              addPoints({
                business_id: userData.business_id,
                user_id: userData.user_id,
                task_id: "review_action",
                gamer_type_id: userData.gamer_type_id,
              });
              setUnsuccessfulDialogOpen(false);
            }}
          >
            Ins Archiv
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Grid style={styles.grids} container spacing={2}>
      <Grid
        item md={12} sm={12} xs={12}
      >
        <Box component="div" display={{ xs: 'none', sm: 'none', md: phases[phaseChosen]["status_id"] > 1 ? 'block' : 'none' }}>
          <Stepper orientation="horizontal" steps={6} activeStep={phases[phaseChosen]["status_id"]-2} alternativeLabel={true}>
            <Step key={1}><StepLabel>Überprüfungsphase</StepLabel></Step>
            <Step key={2}><StepLabel>Diskussionsphase</StepLabel></Step>
            <Step key={2}><StepLabel>Beurteilungsphase</StepLabel></Step>
            <Step key={3}><StepLabel>Entscheidungsphase</StepLabel></Step>
            <Step key={4}><StepLabel>Umsetzungsphase</StepLabel></Step>
            <Step key={5}><StepLabel>Prüfungsphase</StepLabel></Step>
          </Stepper>
        </Box>
        <Box display='flex' justifyContent='center'>
          <Box component="div" display={{ xs: phases[phaseChosen]["status_id"] > 1 ? 'block' : 'none' , sm: phases[phaseChosen]["status_id"] > 1 ? 'block' : 'none' , md: 'none'}}>
            <MobileStepper
              variant="dots"
              position="static" steps={6} activeStep={phases[phaseChosen]["status_id"]-2}
            ></MobileStepper>
          </Box>
        </Box>
      </Grid>
      {phases[phaseChosen]["status_id"] === 3 ? (
        <Grid item md={12} sm={12} xs={12}>
          <Card>
            <CardHeader
              title={"Diskussionsforum"}
              style={styles.cardHeader}
              action={
                discussionEnlarged ? (
                  <IconButton onClick={() => setDiscussionEnlarged(false)}>
                    <ExpandLessIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => {
                    setDiscussionEnlarged(true);
                    updatePropositionTopics(
                      propositionForView.proposition_id
                    );
                    }}>
                    <ExpandMoreIcon />
                  </IconButton>
                )
              }
            />
            {discussionEnlarged ? (
              propositionForView !== null ? (
                <CardContent>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                  >Hier können Sie eine Meinung dazu abgeben, und mit anderen Mitarbeitern über den Vorschlag diskutieren.</Typography>
                  <div>
                    {propositionTopics === null ||
                    propositionTopics.length === 0 ? (
                      <Typography
                        variant="body1"
                        color="textPrimary"
                      >
                        Es gibt noch keine Beiträge zur Diskussion, seien Sie der erste!
                      </Typography>
                    ) : (
                      Object.keys(propositionTopics).map(
                        (keyParent, indexParent) => {
                          const {
                            written_by,
                            written_by_initials,
                            discussion_topic_title,
                            discussion_topic_text,
                            created_at,
                            reply,
                          } = propositionTopics[keyParent];
                          // let initialsParent = this._getInitials(valueParent.user);
                          return (
                            <div>
                              <DiscussionTopic
                                topicPoster={written_by}
                                topicPosterInitials={written_by_initials}
                                topicTitle={discussion_topic_title}
                                topicText={discussion_topic_text}
                                createdAt={created_at}
                                isReply={reply}
                              />
                              {!reply
                                ? propositionTopics[keyParent].replies.map(
                                    (valueReply) => {
                                      const {
                                        written_by,
                                        written_by_initials,
                                        discussion_topic_title,
                                        discussion_topic_text,
                                        created_at,
                                        reply,
                                      } = valueReply;
                                      return (
                                        <DiscussionTopic
                                          topicPoster={written_by}
                                          topicPosterInitials={
                                            written_by_initials
                                          }
                                          topicTitle={discussion_topic_title}
                                          topicText={discussion_topic_text}
                                          createdAt={created_at}
                                          isReply={reply}
                                        />
                                      );
                                    }
                                  )
                                : null}
                            </div>
                          );
                        }
                      )
                    )}
                    <Button
                      onClick={() => {
                        setAddNewTopicOpen(true);
                        setNewTopicTitle(
                          "@: " + propositionForView.proposition_title
                        );
                      }}
                    >
                      Neuen Beitrag
                    </Button>
                    {addNewTopicOpen ? (
                      <div>
                        <Divider />
                        <Typography>Titel: {newTopicTitle}</Typography>

                        <TextField
                          required
                          value={newTopicText}
                          onChange={(event) => {
                            setNewTopicText(event.target.value);
                          }}
                          type="text"
                          id="topicText"
                          label="Beitrag"
                          helperText={"Text für den Beitrag"}
                          multiline
                          rowsMax={3}
                          rows={2}
                          fullWidth
                          margin="normal"
                          variant="outlined"
                        />
                        <Button
                          disabled={!newTopicText}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            addNewTopic(
                              userData.business_id,
                              propositionForView.proposition_id,
                              userData.user_id,
                              newTopicTitle,
                              newTopicText
                            );
                            setNewTopicTitle("");
                            setNewTopicText("");
                            setAddNewTopicOpen(false);
                            resetPropositionTopics();
                            // updatePropositionTopics(propositionForView.proposition_id);
                            addPoints({
                              business_id: userData.business_id,
                              user_id: userData.user_id,
                              task_id: "post_new_topic",
                              gamer_type_id: userData.gamer_type_id,
                            });
                          }}
                        >
                          Abgeben
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </CardContent>
              ) : null
            ) : null}
          </Card>
        </Grid>
      ) : null}
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <CardHeader
            title={"Tabelle für: " + phases[phaseChosen]["title"]}
            style={styles.cardHeader}
            action={
              tableEnlarged ? (
                <IconButton onClick={() => setTableEnlarged(false)}>
                  <ExpandLessIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setTableEnlarged(true)}>
                  <ExpandMoreIcon />
                </IconButton>
              )
            }
          />
          {tableEnlarged ? (
            <div>
              <CardActions>
                {phaseChosen !== "pending" ? (
                  <Button
                    color="primary"
                    variant={view === "all" ? "contained" : "outlined"}
                    onClick={() => {
                      setTableContent(
                        userData.business_id,
                        userData.user_id,
                        phases[phaseChosen]["status_id"],
                        "all",
                        "0"
                      );
                      setView("all");
                    }}
                  >
                    Zeige: Alle
                  </Button>
                ) : null}
                <Button
                  color="primary"
                  variant={view === "mine" ? "contained" : "outlined"}
                  onClick={() => {
                    setTableContent(
                      userData.business_id,
                      userData.user_id,
                      phases[phaseChosen]["status_id"],
                      "mine",
                      "0"
                    );
                    setView("mine");
                  }}
                >
                  Zeige: Meine{" "}
                </Button>
                {phaseChosen === "all" || phaseChosen !== "pending" ? (
                  <Button
                    color="primary"
                    variant={
                      view === "my_responsibility" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setTableContent(
                        userData.business_id,
                        userData.user_id,
                        phases[phaseChosen]["status_id"],
                        "my_responsibility",
                        phases[phaseChosen]["role_id"]
                      );
                      setView("my_responsibility");
                    }}
                  >
                    Zeige: Mir Zugeordnete
                  </Button>
                ) : null}
              </CardActions>
              {!view ? null : (
                <CardContent style={{ maxHeight: 640, overflow: "auto" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Aktion</TableCell>
                        <TableCell>ID</TableCell>
                        <TableCell>Situationstitel</TableCell>
                        <TableCell>Vorschlagstitel</TableCell>
                        <TableCell>Phase</TableCell>
                        {
                          +phases[phaseChosen]["status_id"] === 6 ? 
                            <TableCell>Fortschritt</TableCell>
                          : null
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableContent === undefined || tableContent === null
                        ? null
                        : tableContent.map((value) => {
                            const {
                              proposition_id,
                              status_id,
                              current_situation_title,
                              proposition_title,
                              status_name,
                              implementation_percentage = "1"
                            } = value;
                            return (
                              <TableRow
                        
                                key={value.proposition_id}
                                hover={true}
                              >
                                <TableCell>
                                  <Button
                                    color="inherit"
                                    variant="outlined"
                                    onClick={() => {
                                      setPropositionForView(proposition_id);
                                      setViewPropositionID(proposition_id);
                                      getExtraInfo(proposition_id, status_id);
                                      getLikes(userData.user_id, proposition_id);
                                      setDiscussionEnlarged(false);
                                      setTableEnlarged(false);
                                      setInfoEnlarged(true);
                                      addPoints({
                                        business_id: userData.business_id,
                                        user_id: userData.user_id,
                                        task_id: "show_idea_information",
                                        gamer_type_id: userData.gamer_type_id,
                                      });
                                    }}
                                  >Ansehen</Button>
                                </TableCell>
                                <TableCell>{proposition_id}</TableCell>
                                <TableCell>{current_situation_title}</TableCell>
                                <TableCell>{proposition_title}</TableCell>
                                <TableCell>
                                <MobileStepper
                                  variant="dots"
                                  steps={6}
                                  position="static"
                                  activeStep={+status_id-2}
                                />{status_name}
                                </TableCell>
                                {
                                  +phases[phaseChosen]["status_id"] === 6 ? 
                                <TableCell>
                                  <MobileStepper 
                                    variant="progress"
                                    steps={100}
                                    position="static"
                                    activeStep={implementation_percentage}
                                  />{implementation_percentage} %
                                </TableCell>
                                  : null
                                }
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </CardContent>
              )}
            </div>
          ) : null}
        </Card>
      </Grid>
      {/* --- InformationsArea --- */}
      <Grid item md={12} sm={12} xs={12}>
        <Card>
          <CardHeader
            title={"Info"}
            style={styles.cardHeader}
            action={
              infoEnlarged ? (
                <IconButton onClick={() => setInfoEnlarged(false)}>
                  <ExpandLessIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => setInfoEnlarged(true)}>
                  <ExpandMoreIcon />
                </IconButton>
              )
            }
          />
          {
            infoEnlarged ? (
                propositionForView !== null ? (
                    <div>
                        {
                            phases[phaseChosen]["status_id"] === 3 ? (
                  <div>
                    {propositionLikesCount === null
                      ? getLikes(
                          userData.user_id,
                          propositionForView.proposition_id
                        )
                      : null}
                    <CardActions>
                      <Badge
                        max={99}
                        badgeContent={
                          propositionLikesCount !== null
                            ? propositionLikesCount
                            : 0
                        }
                      >
                        <IconButton
                          color={userLikedProposition ? "primary" : "disabled"}
                          onClick={() => {
                            if (!userLikedProposition) {
                              likeProposition(
                                userData.business_id,
                                userData.user_id,
                                propositionForView.proposition_id,
                                userData.gamer_type_id,
                                userLikedProposition ? -1 : +1
                              );
                              setTimeout(() => {
                                getLikes(
                                  userData.user_id,
                                  propositionForView.proposition_id
                                )
                              }, 333);
                            } else {
                              likeProposition(
                                userData.business_id,
                                userData.user_id,
                                propositionForView.proposition_id,
                                userData.gamer_type_id,
                                userLikedProposition ? -1 : +1
                              );
                              setTimeout(() => {
                                getLikes(
                                  userData.user_id,
                                  propositionForView.proposition_id
                                )
                              }, 333);
                            }
                          }}
                        >
                          <ThumbUpIcon />
                        </IconButton>
                      </Badge>
                      <Button
                        onClick={() => {
                          setDiscussionEnlarged(true);
                          updatePropositionTopics(
                            propositionForView.proposition_id
                          );
                        }}
                      >
                        Diskussionsforum
                      </Button>
                      
                    </CardActions>
                  </div>
                ) : null}
                <CardContent style={{ maxHeight: 640, overflow: "auto" }}>
                    <Typography
                        variant="h5"
                        color="textPrimary"
                    >
                        Information über die Idee
                    </Typography>
                    
                  <InformationArea
                  statusId={propositionForView.status_id}
                    who={{
                      user_id: propositionForView.cs_written_by_user_id,
                      user_full: propositionForView.cs_written_by_user_full,
                      user_initials: propositionForView.cs_written_by_user_initials
                    }}
                    which={"situation"}
                    title={propositionForView.current_situation_title}
                    description={propositionForView.current_situation_text}
                  />
                  <InformationArea
                  statusId={propositionForView.status_id}
                    is_alternative={propositionForView.cs_written_by_user_id !== propositionForView.written_by_user_id}
                    who={{
                      user_id: propositionForView.written_by_user_id,
                      user_full: propositionForView.written_by_user_full,
                      user_initials: propositionForView.written_by_user_initials
                    }}
                    which={"proposition"}
                    title={propositionForView.proposition_title}
                    description={propositionForView.proposition_text}
                  />
                  <AdditionalInfoArea 
                    areas={propositionForView.areas}
                    departments={propositionForView.departments}
                    categories={propositionForView.categories}

                  />
                  {parseInt(propositionForView.status_id) >= 3 ? (
                    <div>
                      {extraInfoContent()}
                    </div>
                  ) : null}
                  {infoActionContent()}
                </CardContent>
              </div>
            ) : null
          ) : null}
        </Card>
      </Grid>
      {unsuccessfulDialog()}
    </Grid>
  );
}
export default TabPhaseContent;

const phases = {
  all: {
    status_id: 0,
    role_id: 0,
    title: "Alle Vorschläge",
  },
  pending: {
    status_id: 1,
    title: "Bearbeitungsphase",
  },
  verification: {
    status_id: 2,
    role_id: 1,
    title: "Überprüfungsphase",
  },
  discussion: {
    status_id: 3,
    role_id: 1,
    title: "Diskussionsphase",
  },
  assessment: {
    status_id: 4,
    role_id: 2,
    title: "Beurteilungsphase",
  },
  decision: {
    status_id: 5,
    role_id: 3,
    title: "Entscheidungsphase",
  },
  implementation: {
    status_id: 6,
    role_id: 4,
    title: "Umsetzungsphase",
  },
  review: {
    status_id: 7,
    role_id: 5,
    title: "Prüfungsphase",
  },
};
