import React, {useContext} from 'react';
import {styles} from "../../styles/lswiStyles";
import {
    Tabs, Tab, AppBar, Container
} from "@material-ui/core";
import TabPhaseContent from "./TabPhaseContent";
import {PhaseContext} from "../../contexts/PhaseContext";

function PhasesTab(){
    const {
        phaseTabValue,
        setPhaseTabValue,
        resetPropositionForView,
        resetTableContent,
    } = useContext(PhaseContext);

    return(
        <div>
            <AppBar
                position="static"
            >
                <Tabs
                    style={styles.phaseTabs}
                    variant="scrollable"
                    scrollButtons="on"
                    value={phaseTabValue}
                    onChange={
                        (event, value) => {
                            setPhaseTabValue(value);
                            resetPropositionForView();
                            resetTableContent();
                        }
                    }
                >
                    <Tab style={styles.tabHeader} label="Alle Phasen" />
                    <Tab style={styles.tabHeader} label="Bearbeitungsphase" />
                    <Tab style={styles.tabHeader} label="Überprüfungsphase" />
                    <Tab style={styles.tabHeader} label="Diskussionsphase" />
                    <Tab style={styles.tabHeader} label="Beurteilungsphase" />
                    <Tab style={styles.tabHeader} label="Entscheidungsphase" />
                    <Tab style={styles.tabHeader} label="Umsetzungsphase" />
                    <Tab style={styles.tabHeader} label="Prüfungsphase" />
                </Tabs>
            </AppBar>
            {phaseTabValue === 0 && <Container maxWidth="md"><TabPhaseContent phaseChosen={"all"} /></Container> }
            {phaseTabValue === 1 && <Container maxWidth="md"><TabPhaseContent phaseChosen={"pending"} /></Container>}
            {phaseTabValue === 2 && <Container maxWidth="md"><TabPhaseContent phaseChosen={"verification"} /></Container>}
            {phaseTabValue === 3 && <Container maxWidth="md"><TabPhaseContent phaseChosen={"discussion"} /></Container>} 
            {phaseTabValue === 4 && <Container maxWidth="md"><TabPhaseContent phaseChosen={"assessment"} /></Container>}
            {phaseTabValue === 5 && <Container maxWidth="md"><TabPhaseContent phaseChosen={"decision"} /></Container>}
            {phaseTabValue === 6 && <Container maxWidth="md"><TabPhaseContent phaseChosen={"implementation"} /></Container>}
            {phaseTabValue === 7 && <Container maxWidth="md"><TabPhaseContent phaseChosen={"review"} /></Container>}
        </div>
    )
}
export default PhasesTab;