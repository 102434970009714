import React, {useState} from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import {styles} from '../../styles/lswiStyles';
import {TextField, Button, Box} from "@material-ui/core";

import Divider from "@material-ui/core/Divider";
import axios from 'axios';
import {
    localIP as ipAddress,
    // onlineIpSecond as IpAddress
} from '../../utilities/urls';

export default function Contact() {
    const [email, setEmail] = useState("");
    const [business, setBusiness] = useState("");
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [description, setDescription] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    function sendRequest(){
        axios({
            method: 'post',
            url: ipAddress + 'send_request.php',
            data: {
                email,
                business,
                fname,
                lname,
                description
            }
        })
        .then(
            res => {
                if(res.data.sent === "true"){
                    console.log("nichts");
                } else {
                    setIsError(true);
                    setErrorMessage(res.data.message === undefined ? "Keine Meldung vorhanden" : res.data.message)
                }
            }
        )
    }
    
    return(
        <Card>
            <CardHeader
                style={styles.cardHeader}
                title="Feedback und Kontakt"
            />
            <CardContent>
                <Grid
                    container spacing={2}
                >
                    <Grid
                        item md={6}
                    >
                        <Typography
                            variant="h5"
                        >
                            Anfrage
                        </Typography>
                        <Divider />
                        <Box display={isError ? 'block' : 'none'}>
                            <Typography
                                variant="h6" color="error"
                            >
                                {errorMessage}
                            </Typography>
                        </Box>
                        <Typography>
                            Wenn Sie Interesse an dieser Plattform haben, dann können Sie hier Ihre Kontaktdaten hinterlassen.<br/>
                            Möchten Sie darüber hinaus auch gerne einen Registrierung bekommen, damit Sie die Plattform gleich benutzen möchten, dann geben Sie das kurz im Punkt <em>"Bemerkung"</em> an.<br/>
                            Wir danken Ihnen schon im Voraus für Ihr Interesse.
                        </Typography>
                        <Divider />
                            <TextField
                                required
                                value={email}
                                onChange={(event, value) => setEmail(event.target.value)}
                                type="email"
                                id="email"
                                label="Email-Adresse"
                                autoFocus
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                required
                                value={fname}
                                onChange={(event, value) => setFname(event.target.value)}
                                type="text"
                                id="fname"
                                label="Vorname"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                required
                                value={lname}
                                onChange={(event, value) => setLname(event.target.value)}
                                type="text"
                                id="lname"
                                label="Nachname"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                required
                                value={business}
                                onChange={(event, value) => setBusiness(event.target.value)}
                                type="text"
                                id="business"
                                label="Unternehmen"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                required
                                value={description}
                                onChange={event => setDescription(event.target.value)}
                                type="text"
                                id="description"
                                label="Bemerkung"
                                fullWidth
                                rows={3}
                                rowsMax={5}
                                margin="normal"
                                variant="outlined"
                            />
                            <Button
                            disabled={
                                (email === "" ||
                                fname === "" ||
                                lname === "" ||
                                business === "" ||
                                description === "")
                            }
                            variant="contained"
                            color="primary"
                            fullWidth
                                onClick={
                                    () => {
                                        sendRequest();
                                        setEmail("");
                                        setDescription("");
                                        setBusiness("");
                                        setFname("");
                                        setLname("")
                                        if(isError){
                                            setIsError(false);
                                            setErrorMessage("")
                                        }
                                    }
                                    
                                }
                            >Senden</Button>
                    </Grid>
                    <Grid
                        item md={6}
                    >
                        <Typography
                            variant="h5"
                        >
                            Kontaktdaten
                        </Typography>
                        <Divider />
                        <Typography
                            variant="h6"
                            color="primary"
                        >
                            Entwickler/Designer
                        </Typography>
                        <Divider variant="inset" />
                        <Typography>
                            Karsten Tauchert<br/>
                            karsten.tauchert@wi.uni-potsdam.de
                        </Typography>
                        <Typography
                            variant="h6"
                            color="primary"
                        >
                            Projektteam:
                        </Typography>
                        <Divider variant="inset"  />
                        
                        <Typography>
                            Benedict Bender<br/>
                            benedict.bender@wi.uni-potsdam.de
                        </Typography>
                        <Divider variant="middle" />
                        <Typography>
                            Marcus Grum<br/>
                            marcus.grum@wi.uni-potsdam.de
                        </Typography>
                        <Divider variant="middle" />
                        <Typography>
                            Johanna Thieme<br/>
                            johanna.thieme@wi.uni-potsdam.de
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}