import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { styles } from "../../styles/lswiStyles";

export default function GamerTypeSurvey() {
  const [surveyArrayGenerated, setSurveyArrayGenerated] = useState(false);
  const [surveyArray, setSurveyArray] = useState([]);
  const [showUnansweredQuestion, setShowUnansweredQuestion] = useState(false);

  const {
    getSurveyQuestions,
    saveSurveyAnswers,
    questions,
    surveyDone,
  } = useContext(AppContext);
  const { userData, updateUserData } = useContext(AuthContext);

  function checkAnswers() {
    let answersCount = 0;
    let rowCount = 0;
    surveyArray.forEach(row => {
      rowCount += 1;
      row.filter(column => column === true ? answersCount += 1 : answersCount += 0);
    });
    // surveyArray.map(
    //   // eslint-disable-next-line array-callback-return
    //   (row) => {
    //     rowCount += 1;
    //     row.map(
    //       // eslint-disable-next-line array-callback-return
    //       (column) => {
    //         if (column) {
    //           answersCount += 1;
    //         }
    //       }
    //     );
    //   }
    // );
    
    if (answersCount === rowCount){
      saveSurveyAnswers(
        userData.user_id,
        surveyArray
      );
      setTimeout(() => {
        updateUserData();
        generateSurveyArray();
      }, 400);
    } else {
      setShowUnansweredQuestion(true);
      setTimeout(() => {
        setShowUnansweredQuestion(false);
      }, 5000);
    }
    return answersCount === rowCount;
  }
  function generateSurveyArray() {
    let surveyAnswersArray = [...Array(18)].map((x) => Array(5).fill(false));
    setSurveyArray(surveyAnswersArray);
    setSurveyArrayGenerated(true);
  }
  const handleRadioChange = (i, j) => {
    // console.log("start change", new Date())
    for (let k = 0; k < 5; k++) {
      surveyArray[i][k] = false;
    }
    surveyArray[i][j] = true;
    setSurveyArray([...surveyArray]);
    // console.log("stopChange", new Date())
  };

  if (!surveyArrayGenerated) {
    setTimeout(() => {
      generateSurveyArray();
    }, 1000);
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        alignSelf="center"
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <div>
        {questions === null ? (
          getSurveyQuestions()
        ) : (
          <div>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Card style={{ margin: 10 }}>
                  <CardHeader style={styles.cardHeader} title="Spielertyp" />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {userData.gamer_type_init === "1" && !surveyDone ? (
                          <Typography variant="h6" color="textPrimary">
                            {userData.gamer_type_init}
                            Ihnen wurde nur ein
                            Initialer-Gamification-Spielertyp zugewiesen, damit
                            Sie Punkte entsprechend ihres Spielertyps sammeln
                            können bewerten Sie kurz diese Aussagen. <br />
                            Mit der Zuweisung eines Spielertyps, werden Ihnen
                            spielertypspezifische Punkte errechnet.
                          </Typography>
                        ) : surveyDone || userData.gamer_type_init === "0" ? (
                          <Typography variant="h6" color="textPrimary">
                            Sie haben dies schon ausgefüllt!
                            <br />
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid item container md={12} spacing={2}>
                        <Grid item md={3}>
                          <Typography color="primary" variant="h6">
                            Fragestellung
                          </Typography>
                        </Grid>
                        <Grid item container md={9} spacing={2}>
                          <Grid item md={2}>
                            <Typography color="primary" variant="h6">
                              Demotiviert
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            {" "}
                          </Grid>
                          <Grid item md={2}>
                            <Typography color="primary" variant="h6">
                              Neutral
                            </Typography>
                          </Grid>
                          <Grid item md={2}>
                            {" "}
                          </Grid>
                          <Grid item md={2}>
                            <Typography color="primary" variant="h6">
                              Motiviert
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {questions.map((value) => {
                        return (
                          <Grid item container md={12} spacing={2}>
                            <Grid item md={3}>
                              {value.value}
                            </Grid>
                            <Grid item container md={9} spacing={2}>
                              <Grid item md={2}>
                                <Radio
                                  checked={
                                    surveyArray[parseInt(value.id) - 1][0]
                                  }
                                  onChange={(event) =>
                                    handleRadioChange(parseInt(value.id) - 1, 0)
                                  }
                                />
                              </Grid>
                              <Grid item md={2}>
                              <Radio
                                    checked={
                                      surveyArray[parseInt(value.id) - 1][1]
                                    }
                                    onChange={(event) =>
                                      handleRadioChange(
                                        parseInt(value.id) - 1,
                                        1
                                      )
                                    }
                                  />
                              </Grid>
                              <Grid item md={2}>
                                <Radio
                                      checked={
                                        surveyArray[parseInt(value.id) - 1][2]
                                      }
                                      onChange={(event) =>
                                        handleRadioChange(
                                          parseInt(value.id) - 1,
                                          2
                                        )
                                      }
                                    />
                              </Grid>
                              <Grid item md={2}>
                                <Radio
                                      checked={
                                        surveyArray[parseInt(value.id) - 1][3]
                                      }
                                      onChange={(event) =>
                                        handleRadioChange(
                                          parseInt(value.id) - 1,
                                          3
                                        )
                                      }
                                    />
                              </Grid>
                              <Grid item md={2}>
                                <Radio
                                      checked={
                                        surveyArray[parseInt(value.id) - 1][4]
                                      }
                                      onChange={(event) =>
                                        handleRadioChange(
                                          parseInt(value.id) - 1,
                                          4
                                        )
                                      }
                                    />
                              </Grid>
                              <Divider />
                            </Grid>
                            <Divider />
                          </Grid>
                        );
                      })}
                      <Grid item md={12}>
                        {
                          <div>
                            <Box display={showUnansweredQuestion ? 'block' : 'none'}>
                            <Typography
                                variant="h5" color="error"
                              >
                                Sie haben eine oder mehrere Bewertungen nicht getätigt. Bitte holen Sie dieses nach, dann können Sie abgeben.
                              </Typography>
                            </Box>
                            
                            <Button
                              // disabled={!checkAnswers()}
                              onClick={() => {
                                checkAnswers();
                                
                              }}
                            >
                              Abgeben
                            </Button>
                          </div>
                        }
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              
              
            </Grid>
          </div>
        )}
      </div>
    );
  }
}
