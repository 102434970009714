
import indigo from '@material-ui/core/colors/indigo'
// import teal from '@material-ui/core/colors/teal'
import purple from '@material-ui/core/colors/purple'
// import green from '@material-ui/core/colors/green'
import lime from '@material-ui/core/colors/lime'

export const styles = {
    appBar: {
        color: 'white',
        backgroundColor: indigo[700]
    },
    mainTabs: {
        backgroundColor: indigo[600]
    },
    phaseTabs: {
        backgroundColor: indigo[500]
    },
    appBarMenuButton: {
        color: 'white',
    },
    appBarIcons: {
        fontSize: '1.1em',
        color: 'white',
        fontWeight: 100
    },
    appBarText: {
        fontSize: '1.2em',
        color: 'white',
        flexGrow: 1
    },
    appBarTextVersion: {
        fontSize: "0.66em",
        color: 'white',
        flexGrow: 1
    },
    grids: {
        //flexDirection: 'start'
        paddingLeft: 20,
        paddingTop: 10
        //paddingRight: 10
    },
    // Card Styles
    cardHeader: {
        backgroundColor: indigo[300],
        color: 'white'
    },
    // ButtonStyles:
    buttonAccept: {
        backgroundColor: lime[600],
        color: 'white',
    },
    buttonDecline: {
        backgroundColor: purple[300],
        color: 'white'
    },
    buttonCardAction: {
        paddingRight: 8
    },
    newUserTextFields: {
        marginTop: 20
    },
    addNewIdeaTextField: {
        marginTop: 20,
        fontSize: '20px'
    },
    drawer: {
        backgroundColor: indigo[300],
        color: 'white'
    },
    tabHeader: {
        textTransform: 'none'
    },
};