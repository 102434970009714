import React, {createContext} from "react";

export const RoutingContext = createContext();

class RoutingContextProvider extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            currentPage: "home",
            startingPage: "home"
        }
    }
    componentDidMount() {
        if(JSON.parse(sessionStorage.getItem('currentPage')) === null){
            sessionStorage.setItem('currentPage', JSON.stringify(this.state.currentPage));
        } else {
            this.setCurrentPage(JSON.parse(sessionStorage.getItem('currentPage')))
        }
    }

    setCurrentPage = (currentPage) => {

        if(currentPage !== null){
            this.setState({
                currentPage
            });
            sessionStorage.setItem('currentPage', JSON.stringify(currentPage));
        }
    };

    render() {
        const {
            currentPage
        } = this.state;

        return(
            <RoutingContext.Provider
                value={{
                    currentPage,
                    setCurrentPage: this.setCurrentPage
                }}
            >
                {this.props.children}
            </RoutingContext.Provider>
        )
    }
}
export default RoutingContextProvider;