// export const localIP = "http://localhost/asik_backend/";
export const localIP_OLD =
  window.location.hostname === "localhost"
    ? "http://" + window.location.hostname + "/asik_backend/"
    : "https://" + window.location.hostname + "/asik_backend/";

export const localIP =
  window.location.hostname !== "asiktool.lswi.de"
    ? "http://localhost/asik/asik_backend/"
    : "https://asiktool.lswi.de/asik_backend/";

