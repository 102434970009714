import React, {useContext} from "react";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {AuthContext} from "../../contexts/AuthContext";
//import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from '@material-ui/icons/Refresh';
import {styles} from "../../styles/lswiStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { List, ListItem, ListItemText, ListItemAvatar} from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CasinoIcon from '@material-ui/icons/Casino';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';

const useStyles = makeStyles((theme ) => ({
    cardSubheading: {
        [theme.breakpoints.down('sm')]:{
            display: 'none'
        },
        [theme.breakpoints.up('md')]:{
            display: 'line'
        }
    }
}));

export default function GamerInfoCard(props){
    const classes = useStyles();
    const {userData, updateUserData} = useContext(AuthContext);

    return(
        <Card>
            <CardHeader
                style={styles.cardHeader}
                title="Spielerinfo"
                subheader={<span className={classes.cardSubheading}>Hier sind alle Ihre wichtigen Daten zusammengefasst.</span>}
                action={
                    <IconButton
                        onClick={
                            () => updateUserData(userData.login_username )
                        }
                    >
                        <RefreshIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <Grid
                    container spacing={2}
                >
                    <Grid
                        item md={12} sm={12} xs={12}
                        
                    >
                        <List>
                            <ListItem>
                                <ListItemAvatar>
                                    <AccountCircleIcon color="primary"/>
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={
                                        <Typography
                                            variant="h5" color="textPrimary"
                                        >
                                            {userData.user_full}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <CasinoIcon color="primary" />
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={
                                        <Typography
                                            variant="h6" color="textPrimary"
                                        >
                                            Standardpunkte: {parseInt(userData.std_points) + parseInt(userData.init_points)}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography
                                            variant="body1" color="textPrimary"
                                        >
                                            Marktpunkte: {parseInt(userData.market_points) + parseInt(userData.init_points)}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemAvatar>
                                    <FitnessCenterIcon color="primary" />
                                </ListItemAvatar>
                                <ListItemText 
                                    primary={
                                        <Typography
                                            variant="h6" color="textPrimary"
                                        >
                                            {userData.type_long}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography>
                                            (Spielertyp)
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    
                </Grid>
            </CardContent>
        </Card>
    )
}