import React, { useContext, useState } from "react";
import CustomSnackbar from "../CustomSnackbar";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import { styles } from "../../styles/lswiStyles";
import Grid from "@material-ui/core/Grid";
import Login from "./Login";
import LandingPage from "./LandingPage";
import Impressum from "./Impressum";
import Contact from "./Contact";
import { AuthContext } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { RoutingContext } from "../../contexts/RoutingContext";
import { version } from "../../utilities/version";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";

import MenuIcon from "@material-ui/icons/Menu";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import GavelIcon from "@material-ui/icons/Gavel";
import Container from '@material-ui/core/Container';
import Divider from "@material-ui/core/Divider";
import ManualVideos from "./ManualVideos";

const useStyles = makeStyles((theme) => ({
  headerText: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "line",
    },
  },
  homeMenuButton: {
    flexGrow: 1,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      display: "line",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopRightIcons: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "line",
    },
  },
  menuButton: {
    color: "white",
  },
}));
// HOME
export default function Home() {
  const classes = useStyles();
  const [infoGridChoice, setInfoGridChoice] = useState("landing_page");
  const [homeMenuDrawerOpen, setHomeMenuDrawerOpen] = useState(false);

  const { userAuthorized } = useContext(AuthContext);
  const { setCurrentPage } = useContext(RoutingContext);

  const homeMenuDrawer = () => {
    return (
      <Drawer
        open={homeMenuDrawerOpen}
        onClose={() => setHomeMenuDrawerOpen(!homeMenuDrawerOpen)}
        anchor={"left"}
      >
        <div>
          <List>
            <ListItem
              button
              onClick={() => setHomeMenuDrawerOpen(!homeMenuDrawerOpen)}
            >
              <ListItemText primary={"Menu schließen"} />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => {
                setHomeMenuDrawerOpen(!homeMenuDrawerOpen);
                setInfoGridChoice("impressum");
              }}
            >
              <ListItemText primary={"Impressum"} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setHomeMenuDrawerOpen(!homeMenuDrawerOpen);
                setInfoGridChoice("contact");
              }}
            >
              <ListItemText primary={"Kontakt"} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setHomeMenuDrawerOpen(!homeMenuDrawerOpen);
                setInfoGridChoice("landing_page");
              }}
            >
              <ListItemText primary={"Main"} />
            </ListItem>
          </List>
        </div>
      </Drawer>
    );
  };

  return (
    <Container maxWidth={"lg"}>
      <AppBar position="static" style={styles.appBar}>
        <Toolbar>
          <div className={classes.headerText}>
            <Typography style={styles.appBarText}>
              ASiK-Plattform <sup style={{ fontSize: "0.66em" }}>{version}</sup>
            </Typography>
          </div>
          <div className={classes.homeMenuButton}>
            <IconButton
              className={classes.menuButton}
              onClick={() => setHomeMenuDrawerOpen(!homeMenuDrawerOpen)}
            >
              <MenuIcon />
            </IconButton>
          </div>
          <div className={classes.desktopRightIcons}>
            <IconButton
              style={styles.appBarIcons}
              size="small"
              onClick={() => setInfoGridChoice("impressum")}
            >
              <GavelIcon /> Impressum
            </IconButton>
            <IconButton
              style={styles.appBarIcons}
              size="small"
              onClick={() => setInfoGridChoice("contact")}
            >
              <ContactSupportIcon /> Kontakt
            </IconButton>
          </div>

          {userAuthorized ? (
            <IconButton
              style={styles.appBarIcons}
              component={Link}
              to={"/private-home"}
              onClick={() => setCurrentPage("private-home")}
            >
              <SportsEsportsIcon />
              Spiel
            </IconButton>
          ) : null}
        </Toolbar>
      </AppBar>
      <Container maxWidth={"md"} >
      <Grid
        style={styles.grids}
        container
        spacing={2}
        direction="row"
        alignContent="center"
        justifyContent="center"
      >
        <Grid item md={2} sm={1} xs={1}>
          
        </Grid>
        <Grid item md={8} sm={10} xs={10}>
          <Login />
        </Grid>
        <Grid item md={2} sm={1} xs={1}>
          
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          {infoGridChoice === "landing_page" ? (
            <LandingPage />
          ) : infoGridChoice === "impressum" ? (
            <Impressum />
          ) : infoGridChoice === "contact" ? (
            <Contact />
          ) : null}
        </Grid>
        <Grid
          item md={12}
        >
          <ManualVideos />
        </Grid>
      </Grid>
      </Container>
      {homeMenuDrawer()}
      <CustomSnackbar />
    </Container>
  );
}
