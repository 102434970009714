export const medalsObject = {
    "aus_der_vergangenheit_lernen": {
        "name": "Aus-der-Vergangenheit-Lerner",
        "active": "aus_der_vergangenheit_lernen",
        "inactive": "aus_der_vergangenheit_lernen_inaktiv",
        "description": "Auszeichnung für häufiges Überprüfen von archivierten Vorschlägen.",
        "gold": "25",
        "silver": "15",
        "bronze": "5",
        "paper": "1",
        "permanent": "25",
    },
    "bewertungsmeister": {
        "name": "Bewertungsmeister",
        "active": "bewertungsmeister",
        "inactive": "bewertungsmeister_inaktiv",
        "description": "Auszeichnung für häufiges \"bewerten\" einer Idee/eines Vorschlags.",
        "gold": "30",
        "silver": "15",
        "bronze": "5",
        "paper": "1",
        "permanent": "30",
    },
    "detailfuchs":{
        "name": "Detailfuchs",
        "active": "detailfuchs",
        "inactive": "detailfuchs_inaktiv",
        "description": "Auszeichnung für häufiges anschauen der Detailansicht eines Vorschlags.",
        "gold": "15",
        "silver": "10",
        "bronze": "5",
        "paper": "1",
        "permanent": "15",
    },
    "diskussionschecker":{
        "name": "Diskussionschecker",
        "active": "diskussionschecker",
        "inactive": "diskussionschecker_inaktiv",
        "description": "Auszeichnung für häufiges anschauen von Ideen/Vorschlägen in der Diskussion.",
        "gold": "30",
        "silver": "15",
        "bronze": "5",
        "paper": "1",
        "permanent": "30",
    },
    "entscheidungsmeister":{
        "name": "Entscheidungsmeister",
        "active": "entscheidungsmeister",
        "inactive": "entscheidungsmeister_inaktiv",
        "description": "Auszeichnung für häufiges \"entscheiden\" über eine Idee/einen Vorschlag.",
        "gold": "30",
        "silver": "15",
        "bronze": "5",
        "paper": "1",
        "permanent": "30",
    },
    "ideenproduzent":{
        "name": "Ideenproduzent",
        "active": "ideenproduzent",
        "inactive": "ideenproduzent_inaktiv",
        "description": "Auszeichnung für häufiges abgeben einer Idee.",
        "gold": "20",
        "silver": "10",
        "bronze": "5",
        "paper": "1",
        "permanent": "20",
    },
    "konkurrenzbeobachter":{
        "name": "Konkurrenzbeobachter",
        "active": "konkurrenzbeobachter",
        "inactive": "konkurrenzbeobachter_inaktiv",
        "description": "Auszeichnung für häufiges anschauen der Ranglisten.",
        "gold": "100",
        "silver": "50",
        "bronze": "20",
        "paper": "1",
        "permanent": "100",
    },
    "levelmeister":{
        "name": "Levelmeister",
        "active": "levelmeister",
        "inactive": "levelmeister_inaktiv",
        "description": "Auszeichnung für das Erreichen des Levels 10.",
        "gold": "10",
        "silver": "5",
        "bronze": "3",
        "paper": "1",
        "permanent": "10",
    },
    "like_meister":{
        "name": "Like-Meister",
        "active": "like_meister",
        "inactive": "like_meister_inaktiv",
        "description": "Auszeichnung für häufiges \"liken\" eines Vorschlages.",
        "gold": "30",
        "silver": "15",
        "bronze": "5",
        "paper": "1",
        "permanent": "30",
    },
    "login_champion":{
        "name": "Login-Champion",
        "active": "login_champion",
        "inactive": "login_champion_inaktiv",
        "description": "Auszeichnung für häufiges \"anmelden\".",
        "gold": "30",
        "silver": "15",
        "bronze": "5",
        "paper": "1",
        "permanent": "30",
    },
    "mitarbeiter_der_woche":{
        "name": "Arbeiter-der-Woche",
        "active": "mitarbeiter_der_woche",
        "inactive": "mitarbeiter_der_woche_inaktiv",
        "description": "Erreichen der Position 1 in der Rangliste für \"Meiste abgegebene Ideen\" während einer Woche.",
        "gold": "4",
        "silver": "3",
        "bronze": "2",
        "paper": "1",
        "permanent": "4",
    },
    "mitarbeiter_des_monats":{
        "name": "Arbeiter-des-Monats",
        "active": "mitarbeiter_des_monats",
        "inactive": "mitarbeiter_des_monats_inaktiv",
        "description": "Erreichen der Position 1 in der Rangliste für \"Meiste abgegebene Ideen\" während eines Monats.",
        "gold": "2",
        "silver": "1",
        "bronze": "",
        "paper": "",
        "permanent": "2",
    },
    "monatsaufgaben_meisterer":{
        "name": "Monatsaufgaben-Meisterer",
        "active": "monatsaufgaben_meisterer",
        "inactive": "monatsaufgaben_meisterer_inaktiv",
        "description": "Auszeichnung für die häufige Erfüllung von Monatsaufgaben.",
        "gold": "6",
        "silver": "4",
        "bronze": "2",
        "paper": "1",
        "permanent": "6",
    },
    "pruefmeister":{
        "name": "Prüfmeister",
        "active": "pruefmeister",
        "inactive": "pruefmeister_inaktiv",
        "description": "Auszeichnung für häufiges annehmen der Verantwortung für eine Idee.",
        "gold": "30",
        "silver": "20",
        "bronze": "5",
        "paper": "1",
        "permanent": "35",
    },
    "stoebermeister":{
        "name": "Stöbermeister",
        "active": "stoebermeister",
        "inactive": "stoebermeister_inaktiv",
        "description": "Auszeichnung für häufiges Anschauen des Archivs.",
        "gold": "30",
        "silver": "15",
        "bronze": "5",
        "paper": "1",
        "permanent": "30",
    },
    "tagesaufgabenmeister":{
        "name": "Tagesaufgaben-Meister",
        "active": "tagesaufgabenmeister",
        "inactive": "tagesaufgabenmeister_inaktiv",
        "description": "Auszeichnung für die häufige Erfüllung von Tagesaufgaben.",
        "gold": "60",
        "silver": "40",
        "bronze": "20",
        "paper": "1",
        "permanent": "60",
    },
    "up_to_date_umsetzer":{
        "name": "Up-To-Date-Umsetzer",
        "active": "up_to_date_umsetzer",
        "inactive": "up_to_date_umsetzer_inaktiv",
        "description": "Auszeichnung für häufiges \"umsetzen\" einer Idee/eines Vorschlags.",
        "gold": "15",
        "silver": "10",
        "bronze": "5",
        "paper": "1",
        "permanent": "15",
    },
    "voschlagschecker":{
        "name": "Vorschlagschecker",
        "active": "vorschlagschecker",
        "inactive": "vorschlagschecker",
        "description": "Auszeichnung für häufiges \"anschauen\" des \"Alle Vorschläge\" Tabs.",
        "gold": "30",
        "silver": "15",
        "bronze": "5",
        "paper": "1",
        "permanent": "30",
    },
    "wechselhafter_typ":{
        "name": "Wechselhafter-Typ",
        "active": "wechselhafter_typ",
        "inactive": "wechselhafter_typ_inaktiv",
        "description": "Auszeichnung für häufiges Wechseln des Spielertyps.",
        "gold": "3",
        "silver": "2",
        "bronze": "1",
        "paper": "",
        "permanent": "3",
    },
    "wochenaufgaben_meisterer":{
        "name": "Wochenaufgaben-Meisterer",
        "active": "wochenaufgaben_meisterer",
        "inactive": "wochenaufgaben_meisterer_inaktiv",
        "description": "Auszeichnung für die häufige Erfüllung von Wochenaufgaben.",
        "gold": "15",
        "silver": "10",
        "bronze": "5",
        "paper": "1",
        "permanent": "15",
    },
};