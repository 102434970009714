import React, {useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import {RoutingContext} from "../../contexts/RoutingContext";
import {withRouter, Link} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import {styles} from "../../styles/lswiStyles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CustomSnackbar from "../CustomSnackbar";
import {Tabs, Tab} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import AddNewIdea from "./AddNewIdea";
import {AppContext} from "../../contexts/AppContext";
import MenuDrawer from "./MenuDrawer";
import GameTab from "../gametab/GameTab";
import PhasesTab from "../phasestab/PhasesTab";
import Badge from "@material-ui/core/Badge";
import AdminView from "./AdminView";
import GamerTypeSurvey from "../surveytab/GamerTypeSurvey";
import ArchiveTab from "../archivetab/ArchiveTab";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HomeIcon from '@material-ui/icons/Home';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
    iconText: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'line',
        }
    },
    headerText: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'line',
        }
    }
}));


function PrivateHome(){
    const classes = useStyles();

    const {
        userData,
    } = useContext(AuthContext);
    const {
        setCurrentPage
    } = useContext(RoutingContext);
    const {
        setAddNewIdeaDialogOpen,
        setMenuDrawerOpen,
        setMainTabValue,
        mainTabValue,
        getSelectData,
    } = useContext(AppContext);

    // const [tabValue, setTabValue] = useState(0);
    return(
        <Container
            maxWidth={"lg"}

        >
            <AppBar
                position="static"
                style={styles.appBar}
            >
                <Toolbar>
                    <IconButton
                        style={styles.appBarMenuButton}
                        onClick={
                            () => {
                                setMenuDrawerOpen(true);
                                getSelectData(userData.business_id)
                            }
                        }
                        edge="start"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        style={styles.appBarText}
                    >
                        <span className={classes.iconText}>ASiK-Plattform</span>
                    </Typography>

                    <Typography
                        style={styles.appBarText}
                    >
                        {
                            userData === undefined || userData === "undefined" || userData === null ?
                                '...' :
                                userData.business_name
                        }<span className={classes.headerText}>:</span> {
                        userData === undefined || userData === "undefined" || userData === null?
                            '...' :
                            <span className={classes.headerText}>{userData.user_full}</span>
                    } ({
                        userData === undefined || userData === "undefined" || userData === null?
                            '...' :
                            userData.department_name
                    })
                    </Typography>

                    <IconButton
                        style={styles.appBarIcons}
                        onClick={() => {
                            setAddNewIdeaDialogOpen(true)
                            getSelectData(userData.business_id)
                        }}
                    >
                        <AddCircleOutlineIcon /> <span className={classes.iconText}>Neue Idee</span>
                    </IconButton>
                    <IconButton
                        style={styles.appBarIcons}
                        component={Link}
                        to="/"
                        onClick={
                            () => {
                                // logoutUser();
                                setCurrentPage('home');
                            }
                        }
                    > <HomeIcon /> <span className={classes.iconText}>Main</span> </IconButton>
                </Toolbar>
            </AppBar>
            <AppBar
                position="static"
            >
                <Tabs
                    style={styles.mainTabs}
                    variant="scrollable"
                    scrollButtons="on"
                    value={mainTabValue}
                    onChange={
                        (event, value) => {
                            setMainTabValue(value)
                        }
                    }
                >
                    <Tab style={styles.tabHeader} label="Game" />
                    <Tab style={styles.tabHeader} label="Übersicht" />
                    {/* <Tab label={"Aufgabenliste"} /> */}
                    <Tab style={styles.tabHeader} label="Archiv" />
                    <Tab style={styles.tabHeader} label={
                        userData.gamer_type_init === "1" || userData.gamer_type_init === 1 ?

                        <Badge
                            badgeContent={"!"} color="secondary"
                        >
                            Spielertyp
                        </Badge> : "Spielertyp"
                    } />
                </Tabs>
            </AppBar>
            <div>  
                {mainTabValue === 0 && <GameTab />}
                {mainTabValue === 1 && <PhasesTab/> }
                {/* {mainTabValue === 2 && <TasksTab /> } */}
                {mainTabValue === 2 && <Container maxWidth="md"><ArchiveTab /></Container>}
                {mainTabValue === 3 && <Container maxWidth="md"><GamerTypeSurvey /></Container>}
            </div>
            {AdminView()}
            <CustomSnackbar />
            <AddNewIdea />
            <MenuDrawer />
        </Container>

    )
}

export default withRouter(PrivateHome);