import React, {useContext} from "react";
import {AuthContext} from "../contexts/AuthContext";
import {Redirect, Route} from "react-router-dom";
import {RoutingContext} from "../contexts/RoutingContext";


export function HomeRoute({children, ...rest}) {
    const {currentPage,} = useContext(RoutingContext);

    return (
        <Route
            {...rest}
            render={
                ({location}) => currentPage === "home" ? (
                    children
                ) : (<Redirect to={{
                        pathname: "/" + currentPage,
                        state: {from: location}
                    }}
                    />
                )

            }
        />
    )
}

export default function PrivateRoute({children, ...rest}){
    const {userAuthorized} = useContext(AuthContext);

    return(
        <Route
            {...rest}
            render={
                ({location}) => userAuthorized  ? (
                    children
                ) : (
                    <Redirect to={{
                        pathname: "/",
                        state: { from: location}
                    }}
                    />
                )
            }
        />
    )
}