import React, {useContext, useState} from "react";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import {AppContext} from "../../contexts/AppContext";
import {AuthContext} from "../../contexts/AuthContext";
import Chart from 'react-apexcharts';
import {styles} from '../../styles/lswiStyles'

export default function DataInfoCard() {
    const [getPhasesCountTriggered, setGetPhasesCountTriggered] = useState(false);

    const {
        phasesCount,
        getPhasesCount
    } = useContext(AppContext);
    const {
        userData
    } = useContext(AuthContext);
    let options = {
        labels: ["Noch keine!"],
        series: [1],
        chart: {
            type: 'donut'
        },
    };
    if(!getPhasesCountTriggered){
        setGetPhasesCountTriggered(true);
        getPhasesCount(userData.business_id);
    }
    if(phasesCount){
        options = {
            labels: phasesCount.labels.length === 0 ? ["Noch keine!"]:  phasesCount.labels,
            series: phasesCount.series.length === 0 ? [1] : phasesCount.series.map((val) => parseInt(val)),
            chart: {
                type: 'donut'
            },
        };
    }
    return(
        <Card>
            <CardHeader
                style={styles.cardHeader}
                title={"Daten"}
                subheader={"Hier werden Ihnen verschiedene Daten angezeigt."}
            />
            <CardContent>
                <Typography>
                    Phasenübersicht:
                </Typography>
                {
                    phasesCount === null ? null :
                        <Chart options={options} series={options.series} type="donut" chartoptions= {options} labels={options.labels}/>
                }
                

            </CardContent>
        </Card>
    )
}