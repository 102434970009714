import React, {useContext, useState} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {styles} from "../../styles/lswiStyles";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import {AuthContext} from "../../contexts/AuthContext";
import {PhaseContext} from "../../contexts/PhaseContext";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {AppContext} from "../../contexts/AppContext";
import { Paper } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";


function InformationArea(props) {
    const { which, title, description } = props;
  
    return (
      <div>
        <Grid
          container
          spacing={2}
          style={{
            paddingBottom: 10,
          }}
        >
          <Grid container item spacing={2} xs={12} sm={12} md={12}>
            <Grid item md={12} sm={12}>
              <Typography variant="h6" color="textPrimary" align="left">
                {which === "proposition" ? "Vorschlag" : "Situation"}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              
              <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Titel</TableCell>
                    <TableCell>Beschreibung</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{title}</TableCell>
                    <TableCell>{description}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

function InformationFromOtherPhases(props) {
    const {
      phase,
      authorFname,
      authorLname,
      comment,
      likesCount,
      topicsCount,
      personDays,
      duration,
      costs,
      risk,
    } = props;
  
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{phase}</Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Autor</TableCell>
                    <TableCell>Kommentar</TableCell>
                    {
                      phase === "Diskussionsphase" ? 
                      <React.Fragment>
                        <TableCell>Anzahl: Likes</TableCell>
                        <TableCell>Anzahl: Topics</TableCell>
                      </React.Fragment> : null
                    }
                    {
                      phase === "Beurteilungsphase" ? 
                      <React.Fragment>
                        <TableCell>Personentage</TableCell>
                        <TableCell>Kosten</TableCell>
                        <TableCell>Dauer</TableCell>
                        <TableCell>Risiko</TableCell>
                      </React.Fragment> : null
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {authorFname === null || authorFname === undefined || authorFname === "" ? 
                        "Kein Autor eines Kommentars" : 
                          authorLname + ", " + authorFname}
                    </TableCell>
                    <TableCell>
                      {comment === null || comment === undefined || comment === "" ? 
                        "Kein Kommentar hinterlassen" : 
                          comment}
                    </TableCell>
                    {
                      phase === "Diskussionsphase" ? 
                      <React.Fragment>
                        <TableCell>{likesCount}</TableCell>
                        <TableCell>{topicsCount}</TableCell>
                      </React.Fragment> : null
                    }
                    {
                      phase === "Beurteilungsphase" ? 
                      <React.Fragment>
                        <TableCell>{personDays}</TableCell>
                        <TableCell>{costs}</TableCell>
                        <TableCell>{duration}</TableCell>
                        <TableCell>{risk}</TableCell>
                      </React.Fragment> : null
                    }
                  </TableRow>
                </TableBody>
              </Table>
              </Paper>
              
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

function ExtraInfoView(props){
    const {
        extraInfoData
    } = props;
    const {
        responsibility_data,
        discussion_data,
        decision_data,
        implementation_data,
      } = extraInfoData;

    return(
        <div>
            <Typography variant="h5" color="textPrimary">
            Information aus anderen Phasen
          </Typography>
          <InformationFromOtherPhases
            phase={"Verantwortungsphase"}
            comment={responsibility_data.responsibility_comment_text}
            authorFname={responsibility_data.first_name}
            authorLname={responsibility_data.last_name}
          />
          <Divider variant="inset" />
          <InformationFromOtherPhases
            phase={"Diskussionsphase"}
            comment={discussion_data.discussion_comment_text}
            authorFname={discussion_data.first_name}
            authorLname={discussion_data.last_name}
            likesCountShow={true}
            likesCount={discussion_data["likes_count"]}
            topicsCount={discussion_data["topics_count"]}
          />
          <Divider variant="inset" />
          <InformationFromOtherPhases
            phase={"Beurteilungsphase"}
            comment={extraInfoData.assessment_data.assessment_comment_text}
            authorFname={extraInfoData.assessment_data.first_name}
            authorLname={extraInfoData.assessment_data.last_name}
            personDays={extraInfoData.assessment_data.person_days}
            duration={extraInfoData.assessment_data.estimated_duration}
            costs={parseInt(extraInfoData.assessment_data.estimated_cost)}
            risk={extraInfoData.assessment_data.estimated_risk}
          />
          <Divider variant="inset" />
          <InformationFromOtherPhases
            phase={"Entscheidungsphase"}
            comment={decision_data.decision_comment_text}
            authorFname={decision_data.first_name}
            authorLname={decision_data.last_name}
          />
          <Divider variant="inset" />
          <InformationFromOtherPhases
            phase={"Implementation"}
            comment={implementation_data.implementation_comment_text}
            authorFname={implementation_data.first_name}
            authorLname={implementation_data.last_name}
          />
          <Typography
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
            variant="h6"
            color="textPrimary"
          >Fortschritt: <em><strong>{implementation_data.implementation_percentage } %</strong></em> </Typography>
          <LinearProgress
            color={"primary"}
            style={{ height: 50 }}
            variant="determinate"
            value={implementation_data.implementation_percentage}
          />
        </div>
    )
}
export default function ArchiveView(props){
    const [infoEnlarged, setInfoEnlarged] = useState(false);
    const [tableEnlarged, setTableEnlarged] = useState(true);
    const [view, setView] = useState('');

    const {
        userData
    } = useContext(AuthContext);
    const {
        getArchivedData,
        tableContent,
        setPropositionForView,
        propositionForView
    } = useContext(PhaseContext);
    const {
        getExtraInfo,
        extraInfoData
    } = useContext(AppContext);

    if(view === ''){
      getArchivedData(
        userData.business_id,
        userData.user_id,
        "0"
        );
        setView('all')
    }

    return(
        <Grid
            container spacing={2}
        >
            <Grid
                item md={12} sm={12} xs={12}
            >
                <Card
                    style={{
                        marginTop: 10
                    }}
                >
                    <CardHeader
                        title={"Tabelle für: Archivierte"}
                        style={styles.cardHeader}
                        action={
                            tableEnlarged ?
                                <IconButton
                                    onClick={() => setTableEnlarged(false)}
                                >
                                    <ExpandLessIcon />
                                </IconButton> :
                                <IconButton
                                    onClick={() => setTableEnlarged(true)}
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                        }
                    />
                    {
                        tableEnlarged ?
                            <div>
                                <CardActions>
                                    <Button
                                        color="primary"
                                        variant={view === "all" ? "contained" : "outlined"}
                                        onClick={
                                            () => {
                                                getArchivedData(
                                                    userData.business_id,
                                                    userData.user_id,
                                                    "0"
                                                );
                                                setView('all')
                                            }

                                        }
                                    >
                                        Zeige: Alle
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant={view === "mine" ? "contained" : "outlined"}
                                        onClick={
                                            () => {
                                                getArchivedData(
                                                    userData.business_id,
                                                    userData.user_id,
                                                    "17"
                                                );
                                                setView('mine')
                                            }

                                        }
                                    >Zeige: Meine </Button>
                                    <Button
                                        color="primary"
                                        variant={view === "successful" ? "contained" : "outlined"}
                                        onClick={
                                            () => {
                                                getArchivedData(
                                                    userData.business_id,
                                                    userData.user_id,
                                                    "8"
                                                );
                                                setView('successful')
                                            }

                                        }
                                    >
                                        Zeige: Erfolgreiche
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant={view === "unsuccessful" ? "contained" : "outlined"}
                                        onClick={
                                            () => {
                                                getArchivedData(
                                                    userData.business_id,
                                                    userData.user_id,
                                                    "9"
                                                );
                                                setView('unsuccessful')
                                            }

                                        }
                                    >Zeige: Nicht erfolgreiche </Button>

                                </CardActions>
                                {
                                    !view ? null :
                                        <CardContent>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Aktion</TableCell>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>Situationstitel</TableCell>
                                                        <TableCell>Vorschlagstitel</TableCell>
                                                        <TableCell>Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        tableContent === undefined || tableContent === null?
                                                            null :
                                                            tableContent.map(
                                                                value => {
                                                                    return(
                                                                        <TableRow
                                                                            key={value.proposition_id}
                                                                            hover
                                                                        
                                                                        >
                                                                            <TableCell><Button
                                                                                variant="outlined"
                                                                                color="inherit"
                                                                                onClick={() => {
                                                                                    setPropositionForView(value.proposition_id);
                                                                                    getExtraInfo(value.proposition_id, "7");
                                                                                    setInfoEnlarged(true)
                                                                                }}
                                                                            >Ansehen</Button></TableCell>
                                                                            <TableCell>{value.proposition_id}</TableCell>
                                                                            <TableCell>{value.current_situation_title}</TableCell>
                                                                            <TableCell>{value.proposition_title}</TableCell>
                                                                            <TableCell
                                                                                style={{
                                                                                    color: value.status_name === "Erfolgreich" ? 'green' : 'red'
                                                                                }}
                                                                            >{value.status_name}</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                }
                                                            )
                                                    }
                                                </TableBody>

                                            </Table>
                                        </CardContent>
                                }


                            </div>
                            :
                            null
                    }
                </Card>
            </Grid>
            <Grid
                item md={12} sm={12} xs={12}
            >
                <Card>
                    <CardHeader
                        title={"Info"}
                        style={styles.cardHeader}

                        action={
                            infoEnlarged ?
                                <IconButton
                                    onClick={() => setInfoEnlarged(false)}
                                >
                                    <ExpandLessIcon />
                                </IconButton> :
                                <IconButton
                                    onClick={() => setInfoEnlarged(true)}
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                        }
                    />
                    {
                        infoEnlarged ?
                            propositionForView !== null ?
                                <div>
                                    <CardContent>
                                    <Typography
                                        variant="h5"
                                        color="textPrimary"
                                    >
                                        Information über die Idee
                                    </Typography>
                                    <InformationArea
                                        which={"situation"}
                                        title={propositionForView.current_situation_title}
                                        description={propositionForView.current_situation_text}
                                    />
                                    <InformationArea
                                        which={"proposition"}
                                        title={propositionForView.proposition_title}
                                        description={propositionForView.proposition_text}
                                    />
                                       
                                        {
                                            parseInt(propositionForView.status_id) >= 3 && extraInfoData !== null ?
                                                <div>
                                                    <Typography
                                                        variant="h6"
                                                    >
                                                        Zusätzliche-Info:
                                                    </Typography>
                                                    <Divider variant={"inset"} />
                                                    <div>
                                                        {
                                                            extraInfoData !== null ?
                                                            <ExtraInfoView extraInfoData={extraInfoData} /> :
                                                            null
                                                        } 
                                                    </div>
                                                </div> : null
                                        }

                                    </CardContent>

                                </div> :
                                null : null
                    }
                </Card>
            </Grid>
        </Grid>
    )
}