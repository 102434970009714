import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { AppContext } from "../../contexts/AppContext";
import DialogContentText from "@material-ui/core/DialogContentText";
import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { AuthContext } from "../../contexts/AuthContext";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { styles } from "../../styles/lswiStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";

import ClearAllIcon from "@material-ui/icons/ClearAll";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  buttonText: {
    fontSize: "0.8em",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "line",
    },
  },
  buttonIcon: {
    fontSize: "0.75em",
    [theme.breakpoints.down("sm")]: {
      display: "line",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobileStyle: {
    [theme.breakpoints.down("sm")]: {
      display: "line",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  desktopStyle: {
    [theme.breakpoints.down("sm")]: {
      display: "line",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function AddNewIdea() {
  const classes = useStyles();

  // TODO: Rest of Proposition/Idea adding procedure
  const [currentSituationTitle, setCurrentSituationTitle] = useState("");
  const [currentSituationText, setCurrentSituationText] = useState("");
  const [propositionTitle, setPropositionTitle] = useState("");
  const [propositionText, setPropositionText] = useState("");
  const [chosenAreas, setChosenAreas] = useState(null);
  const [chosenCategories, setChosenCategories] = useState(null);
  const [chosenDepartments, setChosenDepartments] = useState(null);
  const [chosenUsers, setChosenUsers] = useState(null);

  const { userData } = useContext(AuthContext);
  const {
    snackbarOpen,
    addNewIdeaDialogOpen,
    setAddNewIdeaDialogOpen,
    postNewIdea,
    saveNewIdea,
    areas,
    departments,
    categories,
    users,
    initSaveIdeaData,
    resetInitSaveIdeaData,
    alternativeIdeaData,
    resetAlternativeIdeaData,
    alternativeIdeaDataSet,
    initSaveIdeaDataSet,
  } = useContext(AppContext);

  // if(!departments){
  //     console.log("Select Check1")
  //     getSelectData(userData.business_id)
  // }
  // if(!areas || !departments || !categories || !users){
  //     console.log("Select Check2")
  //     getSelectData(userData.business_id)
  // }

  const resetEntries = () => {
    setChosenAreas(null);
    setChosenCategories(null);
    setChosenDepartments(null);
    setChosenUsers(null);
    resetInitSaveIdeaData();
    resetAlternativeIdeaData();
    setCurrentSituationTitle("");
    setCurrentSituationText("");
    setPropositionTitle("");
    setPropositionText("");
    
  };
  const postAllowed = () => {
    return (
      currentSituationTitle !== "" &&
      currentSituationText !== "" &&
      propositionTitle !== "" &&
      propositionText !== "" &&
      chosenUsers !== null &&
      chosenDepartments !== null &&
      chosenAreas !== null &&
      chosenCategories !== null
    );
  };
  const saveAllowed = () => {
    return (
      currentSituationText !== "" &&
      currentSituationTitle !== "" &&
      propositionTitle !== "" &&
      propositionText !== ""
    );
  };
  const additionalAllowed = () => {
    console.log(alternativeIdeaData.person_responsible_user_id)
    return (
      currentSituationText !== "" &&
      currentSituationTitle !== "" &&
      propositionTitle !== "" &&
      propositionText !== "" 
      // && chosenUsers !== null
    );
  };

  if (
    initSaveIdeaDataSet && currentSituationTitle === "" && propositionTitle === ""
  ) {
    setCurrentSituationTitle(initSaveIdeaData.current_situation_title);
    setCurrentSituationText(initSaveIdeaData.current_situation_text);
    setPropositionTitle(initSaveIdeaData.proposition_title);
    setPropositionText(initSaveIdeaData.proposition_text);
  }
  if (
    alternativeIdeaDataSet && currentSituationTitle === "" && currentSituationText === ""
  ) {
    setCurrentSituationTitle(alternativeIdeaData.current_situation_title);
    setCurrentSituationText(alternativeIdeaData.current_situation_text);
  }


  return (
    <Dialog
    scroll="body"
      // fullScreen={true}
      maxWidth={"md"}
      open={addNewIdeaDialogOpen}
      onClose={() => setAddNewIdeaDialogOpen(false)}
    >
      <DialogTitle style={styles.cardHeader}>
        {initSaveIdeaData !== null
          ? "Gespeicherte Idee"
          : alternativeIdeaData !== null
          ? "Alterantiver Vorschlag"
          : "Neue Idee"}
      </DialogTitle>
      <DialogContent>
        {initSaveIdeaData !== null ? (
          <DialogContentText color="textPrimary" variant="body1">
            Hier sehen Sie Ihre bisher gespeicherten Daten.
            <br />
            Sie können diese jetzt <em>
              bearbeiten und wieder speichern
            </em> oder <em>ergänzen und abgeben</em>.
          </DialogContentText>
        ) : alternativeIdeaData !== null ? (
          <DialogContentText color="textPrimary" variant="body1">
            Geben Sie hier Ihren alternativen Vorschlag ein und geben Sie diesen danach ab.
          </DialogContentText>
        ) : (
          <DialogContentText color="textPrimary" variant="body1">
            Bitte füllen Sie alle Eingabefelder gewissenhaft aus, damit ein guter Überblick zu Ihrer Idee gewonnen werden kann. Geben Sie auch an, wer die Verantwortung für Ihre Idee übernehmen soll.
          </DialogContentText>
        )}

        <Divider />
        <Grid
          style={{ paddingTop: 10 }}
          container
          spacing={2}
          alignContent="flex-start"
          alignItems="flex-start"
          justify="flex-start"
        >
          <Grid item lg={6} md={6} sm={12} style={{ paddingTop: 10 }}>
            
              <Typography align={"right"} variant="h6" color="primary">
                Situationsbeschreibung
              </Typography>
              <Divider variant="inset" />
              <TextField
                style={styles.addNewIdeaTextField}
                //error={false}
                disabled={alternativeIdeaData !== null}
                value={currentSituationTitle}
                onChange={(event) =>
                  setCurrentSituationTitle(event.target.value)
                }
                id="currentSituationTitle"
                label="Situationstitel"
                helperText="Kurzer Titel der momentanen Situation"
                fullWidth
                rows={2}
                multiline
                variant="outlined"
                autoFocus
              />
              <TextField
                //error={true}
                style={styles.addNewIdeaTextField}
                disabled={alternativeIdeaData != null}
                value={currentSituationText}
                onChange={(event) =>
                  setCurrentSituationText(event.target.value)
                }
                id="currentSituationText"
                label="Situationsbeschreibung"
                helperText="Eine Beschreibung/Darstellung der momentanen Situation"
                fullWidth
                multiline
                rows={2}
                rowsMax={6}
                variant="outlined"
              />
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            
              <Typography align={"right"} variant="h6" color="primary">
                Vorschlagsbeschreibung
              </Typography>
              <Divider variant={"inset"} />
              <TextField
                style={styles.addNewIdeaTextField}
                value={propositionTitle}
                onChange={(event) => setPropositionTitle(event.target.value)}
                id="propositionTitle"
                label="Vorschlagstitel"
                helperText="Kurzer Title des Vorschlags"
                fullWidth
                multiline
                rows={2}
                variant="outlined"
              />
              <TextField
                style={styles.addNewIdeaTextField}
                value={propositionText}
                onChange={(event) => setPropositionText(event.target.value)}
                id="propositionText"
                label="Vorschlagsbeschreibung"
                helperText="Eine Beschreibung/Erklärung des Vorschlags"
                fullWidth
                multiline
                rows={2}
                rowsMax={6}
                variant="outlined"
              />
          </Grid>

          {alternativeIdeaData === null ? (
            <Grid container item lg={12} md={12} sm={12} xs={12} spacing={3}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                
                  <Typography style={{ paddingTop: 5 }}>Bereiche:</Typography>
                  <Select
                    placeholder={"Einen/mehrere Bereich(e) wählen..."}
                    options={areas}
                    value={chosenAreas}
                    onChange={(chosenAreas) => setChosenAreas(chosenAreas)}
                    isMulti={true}
                  />
                   <Typography style={{ paddingTop: 5 }}>
                    Abteilungen:
                  </Typography>
                  <Select
                    placeholder={"Eine Abteilung wählen..."}
                    options={departments}
                    value={chosenDepartments}
                    onChange={(chosenDepartments) =>
                      setChosenDepartments(chosenDepartments)
                    }
                    isMulti={false}
                  />

              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>

                  <Typography style={{ paddingTop: 5 }}>Kategorie:</Typography>
                  <Select
                    placeholder={"Einen/mehrere Kategorie(n) wählen..."}
                    options={categories}
                    value={chosenCategories}
                    onChange={(chosenCategories) =>
                      setChosenCategories(chosenCategories)
                    }
                    isMulti={true}
                  />
                  <Typography style={{ paddingTop: 5 }}>
                    Verantwortlicher:
                  </Typography>
                  <Select
                    placeholder={"Einen Verantwortlichen wählen..."}
                    options={users}
                    value={chosenUsers}
                    onChange={(chosenUsers) => setChosenUsers(chosenUsers)}
                    isMulti={false}
                  />
              </Grid>
              
            </Grid>
          ) : (
            null
            // <Grid item lg={6} md={6} sm={12} xs={12}>

            //       <Typography style={{ padding: 10 }}>
            //         Verantwortlicher:
            //       </Typography>
            //       <Select
            //         placeholder={"Einen Verantwortlichen wählen..."}
            //         options={users}
            //         value={chosenUsers}
            //         onChange={(chosenUsers) => setChosenUsers(chosenUsers)}
            //         isMulti={false}
            //       />
            //   </Grid>
          )}

          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* <DialogSelect /> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {alternativeIdeaData === null ? (
          <IconButton variant={"contained"} onClick={() => resetEntries()}>
            <span className={classes.buttonIcon}>
              <ClearAllIcon />
            </span>
            <span className={classes.buttonText}>Alle Einträge löschen</span>
          </IconButton>
        ) : null}
        <IconButton
          variant={"contained"}
          color="secondary"
          onClick={() => {
            resetAlternativeIdeaData();
            resetInitSaveIdeaData();
            resetEntries();
            setAddNewIdeaDialogOpen(false);
            
          }}
        >
          <span className={classes.buttonIcon}>
            <CloseIcon />
          </span>
          <span className={classes.buttonText}>Abbrechen/Schließen</span>
        </IconButton>
        {alternativeIdeaData === null ? (
          <IconButton
            disabled={!postAllowed()}
            variant={"contained"}
            color="primary"
            onClick={() => {
              if (!postAllowed()) {
                alert("Nicht alle Angaben gemacht!");
              } else {
                postNewIdea(
                  initSaveIdeaData !== null,
                  alternativeIdeaData !== null,
                  {
                    is_original_proposition: 1,
                    business_id: userData.business_id,
                    written_by_user_id: userData.user_id,
                    current_situation_title: currentSituationTitle,
                    current_situation_text: currentSituationText,
                    proposition_title: propositionTitle,
                    proposition_text: propositionText,
                    areas_selected: chosenAreas,
                    categories_selected: chosenCategories,
                    departments_selected: chosenDepartments,
                    person_responsible_user_id: alternativeIdeaData === null ? chosenUsers["value"] :alternativeIdeaData.person_responsible_user_id,
                    old_proposition_id:
                      initSaveIdeaData === null
                        ? "0"
                        : initSaveIdeaData.proposition_id,
                    old_current_situation_id:
                      initSaveIdeaData === null
                        ? "0"
                        : initSaveIdeaData.current_situation_id,
                    alt_current_situation_id:
                      alternativeIdeaData === null
                        ? "0"
                        : alternativeIdeaData.current_situation_id,
                  },
                  {
                    business_id: userData.business_id,
                    user_id: userData.user_id,
                    task_id: "post_new_idea",
                    gamer_type_id: userData.gamer_type_id,
                  }
                );
                setTimeout(() => {
                  if (snackbarOpen) {
                    setAddNewIdeaDialogOpen(false);
                  }
                }, 330);
                resetEntries();
                resetAlternativeIdeaData();
                resetInitSaveIdeaData();
              }
            }}
          >
            <span className={classes.buttonIcon}>
              <SendIcon />
            </span>{" "}
            <span className={classes.buttonText}>Abgeben</span>
          </IconButton>
        ) : (
          <IconButton
            //disabled={postAllowed()}
            variant={"contained"}
            color="primary"
            onClick={() => {
              if (!additionalAllowed()) {
                alert("Nicht alle Angaben gemacht!");
              } else {
                postNewIdea(
                  initSaveIdeaData !== null,
                  alternativeIdeaData !== null,
                  {
                    is_original_proposition: 0,
                    business_id: userData.business_id,
                    written_by_user_id: userData.user_id,
                    current_situation_title: currentSituationTitle,
                    current_situation_text: currentSituationText,
                    proposition_title: propositionTitle,
                    proposition_text: propositionText,
                    areas_selected: chosenAreas,
                    categories_selected: chosenCategories,
                    departments_selected: chosenDepartments,
                    person_responsible_user_id: alternativeIdeaData === null ? chosenUsers["value"] :alternativeIdeaData.person_responsible_user_id,
                    old_proposition_id:
                      initSaveIdeaData === null
                        ? "0"
                        : initSaveIdeaData.proposition_id,
                    old_current_situation_id:
                      initSaveIdeaData === null
                        ? "0"
                        : initSaveIdeaData.current_situation_id,
                    alt_current_situation_id:
                      alternativeIdeaData === null
                        ? "0"
                        : alternativeIdeaData.current_situation_id,
                  },
                  {
                    business_id: userData.business_id,
                    user_id: userData.user_id,
                    task_id: "post_new_idea",
                    gamer_type_id: userData.gamer_type_id,
                  }
                );
                setTimeout(() => {
                  if (snackbarOpen) {
                    setAddNewIdeaDialogOpen(false);
                  }
                }, 330);
                resetEntries();
                resetAlternativeIdeaData();
                resetInitSaveIdeaData();
              }
            }}
          >
            <span className={classes.buttonIcon}>
              <SendIcon />
            </span>{" "}
            <span className={classes.buttonText}>Abgeben</span>
          </IconButton>
        )}
        {alternativeIdeaData === null ? (
          <IconButton
            disabled={!saveAllowed()}
            variant={"contained"}
            color="primary"
            onClick={() => {
              if (!saveAllowed()) {
                alert("Nicht alle Angaben gemacht!");
              } else {
                saveNewIdea(
                  {
                    is_original_proposition: 1,
                    business_id: userData.business_id,
                    written_by_user_id: userData.user_id,
                    current_situation_title: currentSituationTitle,
                    current_situation_text: currentSituationText,
                    proposition_title: propositionTitle,
                    proposition_text: propositionText,
                    old_proposition_id:
                      initSaveIdeaData === null
                        ? "0"
                        : initSaveIdeaData.proposition_id,
                    old_current_situation_id:
                      initSaveIdeaData === null
                        ? "0"
                        : initSaveIdeaData.current_situation_id,
                  },
                  initSaveIdeaData !== null ? "reinit" : "init"
                );
                setTimeout(() => {
                    setAddNewIdeaDialogOpen(false);
                  
                }, 330);
                resetEntries()
                resetAlternativeIdeaData();
                resetInitSaveIdeaData();
              }
            }}
          >
            <span className={classes.buttonIcon}>
              <SaveIcon />
            </span>{" "}
            <span className={classes.buttonText}>Speichern</span>
          </IconButton>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
export default withRouter(AddNewIdea);
