import React, {useContext, useState} from "react";
import {AppContext} from "../../contexts/AppContext";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import {AuthContext} from "../../contexts/AuthContext";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {styles} from "../../styles/lswiStyles";
import CardActions from "@material-ui/core/CardActions";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';



export default function AdminView(props) {
    const {
        setAdminViewOpen,
        adminViewOpen,
        addNewUser,
        businessList, getBusinessList,
        usersList, getUsersForBusiness,
    } = useContext(AppContext);
    const {
        userData
    } = useContext(AuthContext);

    // TEXTFIELD DATA
    const [loginUsername, setLoginUsername] = useState("");
    const [loginPassword1, setLoginPassword1] = useState("");
    const [loginPassword2, setLoginPassword2] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [departmentName, setDepartmentName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [businessIdChosen, setBusinessIdChosen] = useState("");
    const [businessNameChosen, setBusinessNameChosen] = useState("");
    const [newAdminExpanded, setNewAdminExpanded] = useState(false);

    const handleNewAdminExpandClick = () => {
        setNewAdminExpanded(!newAdminExpanded);
    };
    if(userData.business_name !== "LSWI" && usersList === null){
        getUsersForBusiness(userData.business_id);
    }
    return(
        <Dialog
            open={adminViewOpen}
            maxWidth={"lg"}
            onClose={() => setAdminViewOpen(false)}
        >
            <DialogTitle

                style={styles.cardHeader}
            >Admin-Ansicht</DialogTitle>
            <DialogContent>

                    <Grid
                        container spacing={2}
                    >
                        <Grid
                            item md={12} sm={12} xs={12}
                        >
                            {
                                userData.business_name === "LSWI" && userData.department_name === "Admin" ?
                                    <Card>
                                        <CardHeader
                                            style={styles.cardHeader}
                                            title={"Anlegen eines Admin für neues Unternehmen"}
                                            subheader={"Da Sie zum LSWI und zu den Admins gehören haben Sie diese Macht! (kein Anderer sieht dieses) "}
                                            action={
                                                <IconButton
                                                    onClick={handleNewAdminExpandClick}
                                                    aria-expanded={newAdminExpanded}
                                                    aria-label="show more"
                                                >
                                                    {
                                                        newAdminExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />
                                                    }
                                                </IconButton>
                                            }
                                        />
                                        <Collapse
                                            in={newAdminExpanded}
                                        >
                                            <CardContent>
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="text"
                                                    value={firstName}
                                                    onChange={event => setFirstName(event.target.value)}
                                                    label={"Vorname"}
                                                    placeholder={""}
                                                    fullWidth
                                                    id="first_name"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="text"
                                                    value={lastName}
                                                    onChange={event => setLastName(event.target.value)}
                                                    label={"Nachname"}
                                                    placeholder={""}
                                                    fullWidth
                                                    id="last_name"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="text"
                                                    value={businessName}
                                                    onChange={event => setBusinessName(event.target.value)}
                                                    label={"Unternehmensname"}
                                                    placeholder={"Name des Unternehmens"}
                                                    fullWidth
                                                    id="business_name"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="text"
                                                    value={departmentName}
                                                    onChange={event => setDepartmentName(event.target.value)}
                                                    label={"Abteilungsname"}
                                                    placeholder={"Name der Abteilung"}
                                                    fullWidth
                                                    id="deaprtment_name"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="text"
                                                    value={loginUsername}
                                                    onChange={event => setLoginUsername(event.target.value)}
                                                    label={"Benutzername/E-Mail"}
                                                    placeholder={"Benutzname/E-Mail"}
                                                    fullWidth
                                                    id="username"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="password"
                                                    value={loginPassword1}
                                                    error={loginPassword1.length <= 8}
                                                    onChange={event => setLoginPassword1(event.target.value)}
                                                    label={"Passwort"}
                                                    placeholder={""}
                                                    fullWidth
                                                    id="password_1"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="password"
                                                    error={loginPassword2 !== loginPassword1}
                                                    value={loginPassword2}
                                                    onChange={event => setLoginPassword2(event.target.value)}
                                                    label={"Passwort wiederholen"}
                                                    placeholder={""}
                                                    fullWidth
                                                    id="password_2"
                                                />
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    disabled={loginPassword1 !== loginPassword2}
                                                    onClick={
                                                        () => {
                                                            addNewUser({
                                                                login_username: loginUsername,
                                                                login_password: loginPassword2,
                                                                department_name: departmentName,
                                                                business_name: businessName,
                                                                first_name: firstName,
                                                                last_name: lastName,

                                                            })
                                                        }
                                                    }
                                                >
                                                    Anlegen
                                                </Button>
                                            </CardActions>
                                        </Collapse>
                                    </Card> : null
                            }
                        </Grid>
                        <Grid
                            item md={6} sm={12} xs={12}
                        >
                            {
                                userData.business_name === "LSWI" && userData.department_name === "Admin" ?
                                    <Card>
                                        <CardHeader
                                            style={styles.cardHeader}
                                            title={"Liste: Unternehmen"}
                                        />
                                        <CardContent>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Unternehmens-ID</TableCell>
                                                        <TableCell>Unternehmens-Name</TableCell>
                                                        <TableCell>Teilnehmer im Unternehmen</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        businessList === null
                                                        || businessList === undefined ?
                                                            getBusinessList(userData.department_name, userData.business_name) :
                                                            businessList.map(
                                                                value => {
                                                                    return(
                                                                        <TableRow
                                                                            key={value.business_id}
                                                                            hover
                                                                            //style={{backgroundColor: businessIdChosen === value.business_id ? "grey" : null}}
                                                                            selected={businessIdChosen === value.business_id}
                                                                            onClick={
                                                                                () => {
                                                                                    getUsersForBusiness(value.business_id);
                                                                                    setBusinessIdChosen(value.business_id);
                                                                                    setBusinessNameChosen(value.business_name);
                                                                                }
                                                                            }
                                                                        >
                                                                            <TableCell>{value.business_id}</TableCell>
                                                                            <TableCell>{value.business_name}</TableCell>
                                                                            <TableCell>{value.users_count}</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                }
                                                            )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </CardContent>
                                    </Card> : null
                            }
                        </Grid>
                        <Grid
                            item md={6} sm={12}
                        >
                            <Card>
                                <CardHeader
                                    style={styles.cardHeader}
                                    title={"Liste: Mitarbeiter"}
                                />
                                <CardContent>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Benutzer-ID</TableCell>
                                                <TableCell>Nachname</TableCell>
                                                <TableCell>Vorname</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                usersList === null || usersList === undefined ?
                                                    null :
                                                    usersList.map(
                                                        value => {
                                                            return(
                                                                <TableRow
                                                                    key={value.user_id}
                                                                >
                                                                    <TableCell>{value.user_id}</TableCell>
                                                                    <TableCell>{value.last_name}</TableCell>
                                                                    <TableCell>{value.first_name}</TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    )
                                            }
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>

                         <Grid
                            item md={6} sm={12} xs={12}
                        >
                            <Card>
                                <CardHeader
                                    style={styles.cardHeader}
                                    title={"Anlegen: Neues Passwort: Mitarbeiter"}
                                />
                                <CardContent>

                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            item md={6} sm={12} xs={12}
                        >
                            <Card
                            >
                                <CardHeader
                                    style={styles.cardHeader}
                                    title={"Anlegen: Neuen Mitarbeiter"}
                                />
                                <CardContent>
                                    {
                                        businessNameChosen.length === 0 ?
                                            <Typography>
                                                Bitte ein Unternhemen Wählen!
                                            </Typography> :
                                            <div>
                                                <Typography>
                                                    Legen Sie jetzt ein neuen Nutzer für das Unternehmen <b>{businessNameChosen}</b> an!
                                                </Typography>
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="text"
                                                    value={firstName}
                                                    onChange={event => setFirstName(event.target.value)}
                                                    label={"Vorname"}
                                                    placeholder={""}
                                                    fullWidth
                                                    id="first_name"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="text"
                                                    value={lastName}
                                                    onChange={event => setLastName(event.target.value)}
                                                    label={"Nachname"}
                                                    placeholder={""}
                                                    fullWidth
                                                    id="last_name"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="text"
                                                    value={departmentName}
                                                    onChange={event => setDepartmentName(event.target.value)}
                                                    label={"Abteilungsname"}
                                                    placeholder={"Name der Abteilung"}
                                                    fullWidth
                                                    id="deaprtment_name"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="text"
                                                    value={loginUsername}
                                                    onChange={event => setLoginUsername(event.target.value)}
                                                    label={"Benutzername/E-Mail"}
                                                    placeholder={"Benutzname/E-Mail"}
                                                    fullWidth
                                                    id="username"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="password"
                                                    value={loginPassword1}
                                                    error={loginPassword1.length <= 8}
                                                    onChange={event => setLoginPassword1(event.target.value)}
                                                    label={"Passwort"}
                                                    placeholder={""}
                                                    fullWidth
                                                    id="password_1"
                                                />
                                                <TextField
                                                    style={styles.newUserTextFields}
                                                    variant="outlined"
                                                    type="password"
                                                    error={loginPassword2 !== loginPassword1}
                                                    value={loginPassword2}
                                                    onChange={event => setLoginPassword2(event.target.value)}
                                                    label={"Passwort wiederholen"}
                                                    placeholder={""}
                                                    fullWidth
                                                    id="password_2"
                                                />

                                            </div>
                                    }
                                </CardContent>
                                <CardActions>
                                    <Button
                                        onClick={
                                            () => {
                                                addNewUser({
                                                    login_username: loginUsername,
                                                    login_password: loginPassword2,
                                                    department_name: departmentName,
                                                    business_name: businessNameChosen,
                                                    first_name: firstName,
                                                    last_name: lastName,
                                                })
                                            }
                                        }
                                    >
                                        Anlegen
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                    </Grid>

            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setAdminViewOpen(false)}
                >Schließen</Button>
            </DialogActions>
        </Dialog>
    )
}