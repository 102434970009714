import React, {createContext, Component} from "react";
import axios from 'axios';
import {
    localIP as ipAddress,
    // onlineIpMain as ipAddress
} from '../utilities/urls';

// Context API
export const AuthContext = createContext();
export const AuthContextConsumer = AuthContext.Consumer;

class AuthContextProvider extends Component{
    constructor(props) {
        super(props);
        this.state = {
            userAuthorized: false,
            authenticationError: false,
        }
    }

    componentDidMount() {

        if(
            sessionStorage.getItem('userAuthorized') !== undefined ||
            JSON.parse(sessionStorage.getItem('userAuthorized'))
        ) {
            this.setState({
                userAuthorized: JSON.parse(sessionStorage.getItem('userAuthorized')),
                userData: JSON.parse(sessionStorage.getItem('userData'))
            });
        }
    }
    getUserAuthorized = () => {
        return(
            this.state.userAuthorized
        )
    };
    getUserData = () => {
        return(
            this.state.userData
        )
    };

    logoutUser = () => {
        this.setState({
            userAuthorized: false,
            authenticationError: false,
            userData: {}
        });
        sessionStorage.removeItem('userAuthorized');
        sessionStorage.removeItem('userData');

    };
    loginUser = (username, password) => {
        this.userAuthorization(username, password)
    };
    userAuthorization = (username, password) => {

        axios({
            method: 'post',
            url: ipAddress + "user_authorization.php",
            data: {
                username,
                password
            }
        })
            .then(
                res => {
                    if(res.data.user_authorized){
                        this.setState({
                            userAuthorized: true,
                            userData: res.data.user_data,
                            authenticationError: false
                        });
                        sessionStorage.setItem('userAuthorized', JSON.stringify(true));
                        sessionStorage.setItem('userData', JSON.stringify(res.data.user_data));
                    } else {
                        this.setState({
                            userAuthorized: false,
                            userData: {},
                            authenticationError: true
                        })
                    }
                }
            )
            .catch(
                error => console.log("error")
            )
    };


    // **** UPDATER ****
    updateUserData = () => {
        const {
            login_username
        } = this.state.userData;


        axios({
            method: 'post',
            url: ipAddress + 'update_user_data.php',
            data: {
                username: login_username
            }
        })
            .then(
                res => {
                    this.setState({
                        userData: res.data.user_data
                    });
                    sessionStorage.setItem('userData', JSON.stringify(res.data.user_data));
                }
            )
            .catch(
                error => console.log("error")
            )
    };


    render() {
        const {
            authenticationError,
            userAuthorized,
            userData
        } = this.state;

        return(
            <AuthContext.Provider
                value={{
                    authenticationError,
                    userAuthorized,
                    userData,
                    loginUser: this.loginUser,
                    logoutUser: this.logoutUser,
                    updateUserData: this.updateUserData,
                    getUserData: this.getUserData,
                    getUserAuthorized: this.getUserAuthorized,
                }}
            >
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

export default AuthContextProvider;

/*

export const AuthContextProvider = (props) => {
    const [userAuthorized, setUserAuthorized] = useState("");
    const [authenticationError, setAuthenticationError] = useState("");
    const [sessionStorageChecked, setSessionStorageChecked] = useState(false);

    const logoutUser = () => {
        setUserAuthorized("")
    };
    const loginUser = (username, password) => {
        userAuthorization(username, password)
    };
    const userAuthorization = (username, password) => {
        console.log("userAutho");
        console.log("Username: " + username + "\nPassword: " + password);
        axios({
            method: 'post',
            url: ipAddress + "user_authorization.php",
            data: {
                username,
                password
            }
        })
            .then(
                res => {
                    console.log(res);
                    if(res.data.user_authorized){
                        setUserAuthorized("1");
                        setAuthenticationError("0");
                        sessionStorage.setItem('userAuthorized', "")
                    } else {
                        setUserAuthorized("");
                        setAuthenticationError("1")
                    }
                }
            )
            .catch(
                error => console.log(error)
            )
    };
    const checkOldData = () => {
        if(sessionStorage.getItem('userAuthorized') !== undefined){
            sessionStorage.getItem('userAuthorized')
            setUserAuthorized(sessionStorage.getItem('userAuthorized'))
        } else {
            console.log("Keiner vorher angemeldet!")
        }
    };

    checkOldData();

    return(
        <AuthContext.Provider
            value={{
                authenticationError,
                userAuthorized,
                loginUser: loginUser,
                logoutUser: logoutUser,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
};*/
