import React, {useContext, useState} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {styles} from "../../styles/lswiStyles";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import {AppContext} from "../../contexts/AppContext";
import {AuthContext} from "../../contexts/AuthContext";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {indigo} from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";

let updateCounter = 0;

export default function RankingInfoCard() {

    const [rankTable, setRankTable] = useState(null);
    const [rankInfoDialogOpen, setRankInfoDialogOpen] = useState(false);

    const {
        userData
    } = useContext(AuthContext);
    const {
        rankingMostLikesTop,
        // ownRankingMostLikes,
        rankingMostLikes,
        rankingMostPointsTop,
        rankingMostPoints,
        rankingMostIdeasTop,
        rankingMostIdeas,
        // ownRankingMostIdeas,
        updateRankingData,
        addPoints,
    } = useContext(AppContext);

    const rankingLikesImage = require('../../images/aktiv/ranking_meiste_likes.png');
    const rankingPointsImage = require('../../images/aktiv/ranking_meiste_punkte.png');
    const rankingIdeasImage = require('../../images/aktiv/ranking_meiste_ideen.png');

    if(updateCounter === 0){
        updateRankingData(
            userData.business_id,
            userData.user_id
        );
        updateCounter = 1;
    }

    const tableTopLikes = () => {

        return(
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Anzahl an Likes</TableCell>
                        <TableCell>Vorschlag-Titel</TableCell>
                        <TableCell>Ersteller</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rankingMostLikes.map(
                            row => {
                                return(
                                    <TableRow>
                                        <TableCell>{row.votes_count}</TableCell>
                                        <TableCell>{row.proposition_title}</TableCell>
                                        <TableCell>{row.full_name}</TableCell>
                                    </TableRow>
                                )
                            }
                        )
                    }
                </TableBody>
            </Table>
        )
    };
    const tableTopPoints = () => {

        return(
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Punkte</TableCell>
                        <TableCell>Teilnehmer</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rankingMostPoints.map(
                            row => {
                                return(
                                    <TableRow>
                                        <TableCell>{row.std_points} SP ({row.market_points} MP)</TableCell>
                                        <TableCell>{row.full_name}</TableCell>

                                    </TableRow>
                                )
                            }
                        )
                    }
                </TableBody>
            </Table>
        )
    };
    const tableTopIdeas = () => {

        return(
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Anzahl</TableCell>
                        <TableCell>Teilnehmer</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rankingMostIdeas.map(
                            row => {
                                return(
                                    <TableRow>
                                        <TableCell>{row.propositions_count}</TableCell>
                                        <TableCell>{row.full_name}</TableCell>

                                    </TableRow>
                                )
                            }
                        )
                    }
                </TableBody>
            </Table>
        )
    };
    const renderRankInfoDialog = () => {

        return(
            <Dialog
                open={rankInfoDialogOpen}
                maxWidth="md"
                onClose={() => setRankInfoDialogOpen(false)}
            >
                <DialogTitle
                    style={
                        {backgroundColor: indigo[500],
                            color: 'white' }
                    }
                >
                    Bestenliste der {
                    rankTable === "likes" ?
                        "Anzahl der Likes" :
                        rankTable === "points" ?
                            "Anzahl der erreichten Punkte" :
                            rankTable === "ideas" ?
                                "Anzahl der erstellten Ideen" :
                                null
                }
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            md={12}
                        >
                            {
                                rankTable === "likes" ?
                                    tableTopLikes() :
                                    rankTable === "points" ?
                                        tableTopPoints() :
                                        rankTable === "ideas" ?
                                            tableTopIdeas() :
                                            null
                            }
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setRankInfoDialogOpen(false)}
                        variant="outlined"
                        color="primary"
                    >
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    function toggleRankingDialogView(view){

        switch (view) {
            case 'likes':
                setRankTable("likes");
                setRankInfoDialogOpen(true);
                break;
            case 'points':
                setRankTable("points");
                setRankInfoDialogOpen(true);
                break;
            case 'ideas':
                setRankTable("ideas");
                setRankInfoDialogOpen(true);
                break;
            
            default:
                break;
        }

        addPoints({
            business_id: userData.business_id,
            user_id: userData.user_id,
            task_id: 'show_ranking_list',
            gamer_type_id: userData.gamer_type_id
        })
    }

    return(
        <Card>
            <CardHeader
                style={styles.cardHeader}
                title="Ranking"
                subheader="Hier sehen Sie, wer an der Spitze steht."
                action={
                    <IconButton
                        onClick={
                            () => updateRankingData(
                                userData.business_id,
                                userData.user_id
                            )
                        }
                    >
                        <RefreshIcon />
                    </IconButton>
                }
            />
            <CardContent>
                <Grid
                    container spacing={2}
                >
                    <Grid
                        item md={12}
                    >
                        <List>
                            <ListItem
                                key="top_likes"
                                button
                                onClick={
                                    () => {
                                        toggleRankingDialogView(
                                            'likes'
                                        )
                                    }
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        src={rankingLikesImage}
                                    >
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                disableTypography
                                    primary={

                                        <React.Fragment>
                                            <Typography
                                                variant="h5" color="textPrimary"
                                            >
                                                Meisten Likes (Vorschlag)
                                            </Typography>
                                            {
                                                rankingMostLikesTop === undefined || rankingMostLikesTop === null ?
                                                    <div>
                                                        <Typography>
                                                        Es gibt noch keinen
                                                    </Typography> <Divider />
                                                    </div>:
                                                    <div>
                                                        <Divider />
                                                        <Typography
                                                            variant="h6" color="textPrimary"
                                                        >
                                                            ({rankingMostLikesTop.votes_count} Likes) Titel: {rankingMostLikesTop.proposition_title}
                                                        </Typography>
                                                    </div>
                                            }
                                        </React.Fragment>
                                    }
                                    secondary={
                                        rankingMostLikesTop === undefined || rankingMostLikesTop === null ?
                                                    <React.Fragment>
                                                        <Typography>
                                                        Es gibt noch keinen
                                                    </Typography> <Divider />
                                                    </React.Fragment>:
                                                    <div>
                                                        <Divider />
                                                        <Typography>
                                                            Beschreibung: {rankingMostLikesTop.proposition_text}
                                                        </Typography>
                                                    </div>
                                    }
                                />
                            </ListItem>
                            <ListItem
                                key="top_points"
                                button
                                onClick={
                                    () => {
                                        toggleRankingDialogView(
                                            'points'
                                        )
                                    }
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        src={rankingPointsImage}
                                    >
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Typography
                                                variant="h5" color="textPrimary"
                                            >
                                                Meisten Punkte (Teilnehmer):
                                            </Typography>
                                            {
                                                rankingMostPointsTop === undefined || rankingMostPointsTop === null ?
                                                    <div>
                                                        <Divider />
                                                        <Typography>
                                                            Noch keiner in der Liste
                                                        </Typography>
                                                    </div> :
                                                    <div>
                                                        <Divider />
                                                        <Typography
                                                            variant="h6" color="textPrimary"
                                                        >
                                                            {rankingMostPointsTop.std_points} Standard-Punkte ({rankingMostPointsTop.market_points} Markt-Punkte)
                                                        </Typography>
                                                    </div>
                                            }
                                        </React.Fragment>
                                    }
                                    secondary={
                                        rankingMostPointsTop === undefined || rankingMostPointsTop === null ?
                                            "" :
                                                <Typography

                                                >
                                                   Teilnehmer: {rankingMostPointsTop.full_name}
                                                </Typography>
                                    }
                                />
                            </ListItem>
                            <ListItem
                                key="top_ideas"
                                button
                                onClick={
                                    () => {
                                        toggleRankingDialogView(
                                            'ideas'
                                        )
                                    }
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        src={rankingIdeasImage}
                                    >
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                disableTypography
                                    primary={
                                        <React.Fragment>
                                            <Typography
                                                variant="h5" color="textPrimary"
                                            >
                                                Meisten Ideen (Teilnehmer)
                                            </Typography>
                                            {
                                                rankingMostIdeasTop === undefined || rankingMostIdeasTop === null ?
                                                    <div>
                                                        <Divider />
                                                        <Typography
                                                        >
                                                            Noch keine Idee vorgeschlagen
                                                        </Typography>
                                                    </div> :
                                                    <div>
                                                        <Divider />
                                                        <Typography
                                                            variant="h6" color="textPrimary"
                                                        >
                                                            { 
                                                                +rankingMostIdeasTop.propositions_count > 1 ? 
                                                                    rankingMostIdeasTop.propositions_count + " Ideen" :
                                                                    (+rankingMostIdeasTop.propositions_count === 1 ? "Eine Idee" : "Keine Idee")
                                                            }
                                                        </Typography>
                                                    </div>
                                            }
                                        </React.Fragment>
                                    }
                                    secondary={
                                        rankingMostIdeasTop === undefined || rankingMostIdeasTop === null ?
                                            <div>
                                                <Divider />
                                                <Typography
                                                >
                                                    
                                                </Typography>
                                            </div> :
                                            <div>
                                                <Divider />
                                                <Typography
                                                >
                                                   Teilnehmer: {rankingMostIdeasTop.full_name}
                                                </Typography>
                                            </div>
                                            
                                    }
                                />
                            </ListItem>
                        </List>
                        {renderRankInfoDialog()}
                    </Grid>
                    <Grid>

                    </Grid>
                    <Grid>

                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    )
}