import React from 'react';
import {Typography} from "@material-ui/core";
import {Card, CardHeader, CardContent} from '@material-ui/core';
import {styles} from '../../styles/lswiStyles';
// import Divider from "@material-ui/core/Divider";

export default function LandingPage(){

    return(
        <div>
            <Card
                raised={true}
            >
                <CardHeader
                    style={styles.cardHeader}
                    title="Willkommen auf der Plattform für Adaptive Spielifizierung in KVP"
                />
                <CardContent>
                    <Typography
                        align="justify"
                    >
                        Das Ziel des Projektes ist die Entwicklung und Anwendung eines Spielifizierungskonzeptes für den kontinuierlichen Verbesserungsprozess (KVP) von kleinen und mittelständischen Unternehmen (KMU), das sich adaptiv an die individuellen Bedürfnisse der Nutzenden anpasst. Es werden akute Probleme, wie z.B. die sinkende Beitragsqualität und -quantität sowie hohe Kosten im Zeitverlauf aufgegriffen und gelöst. Durch den Einsatz von Spielifizierungselementen werden die intrinsische Motivation und das Engagement der Mitarbeitenden erhöht, wodurch KMUs das Mitarbeitendenpotenzial für den KVP effizienter nutzen können. Im Rahmen des Verbundprojektes des Lehrstuhles für Wirtschaftsinformatik, insb. Prozesse und Systeme an der Universität Potsdam sowie dem Forschungsinstitut Unternehmensführung, Logistik und Produktion der TU München wird das ASiK-Tool entwickelt, welches für KMU aktuell frei zugänglich ist.
                        <br/>
                        Das Projekt erfolgt in Zusammenarbeit mit einem projektbegleitenden Ausschuss (pbA), welcher aktuell aus elf Unternehmen besteht. Gerne können interessierte Unternehmen Kontakt zu den Projektzuständigen aufnehmen, um über eine zukünftige Zusammenarbeit zu sprechen.

                    </Typography>
                    {/* <Divider /> */}
                    {/* <img width="100%" alt={"participants"} src={require('../../images/participants.png')} /> */}
                </CardContent>
            </Card>
        </div>
    )
}
