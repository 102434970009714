import React, {useContext} from 'react';
import {Snackbar, Typography} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Divider from "@material-ui/core/Divider";
import {AppContext} from "../contexts/AppContext";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomSnackbar(){

    const {
        standardPoints,
        marketPoints,
        initPoints,
        marketFactor,
        snackbarOpen,
        messageType,
        handleClose
    } = useContext(AppContext);

    if(standardPoints === "0" || standardPoints === 0){
        return(null);
    } else {

        return(
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}
            >
                <Alert
                    onClose={handleClose} severity={messageType}
                >
                    {
                        initPoints > 0 ? <div>
                            <Typography>
                                Prima! Extra Belohnung!
                            </Typography><Divider style={{backgroundColor : 'white'}}/>
                        </div> : null
                    }
                    <Typography>
                        Gamification-Punkte: {parseInt(initPoints) + parseInt(standardPoints)} Punkte<br/>
                    </Typography>
                    <Divider style={{backgroundColor : 'white'}}/>
                    <Typography>
                        Marktfaktor: {marketFactor}<br/>
                        Gesamtpunkte: {parseFloat(initPoints) + parseFloat(marketPoints)} Punkte
                    </Typography>


                </Alert>
            </Snackbar>
        )
    }


}